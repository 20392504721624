<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">
        Tender Winning
      </h2>
    </div>
    <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5 mb-5">
              <div class="col-span-6 md:col-span-3 xl:col-span-3">
                <div v-if="pageData.tenderDetail != ''">
                  <TomSelect disabled v-model="formData.tender_id" :options="{}">
                    <option value="0">Select Tender</option>
                    <option v-for="(tender, index) in pageData.tender_list" :key="index" :value="tender.id">{{
                      tender.tender_name }}</option>
                  </TomSelect>
                </div>

                <div v-else>
                  <TomSelect v-model="formData.tender_id" :options="{}">
                    <option value="0">Select Tender</option>
                    <option v-for="(tender, index) in pageData.tender_list" :key="index" :value="tender.id">{{
                      tender.tender_name }}</option>
                  </TomSelect>
                </div>

                <template v-if="validate.tender_id.$error">
                  <div v-for="(error, index) in validate.tender_id.$errors" :key="index" class="text-theme-6 mt-2">
                    {{ error.$message }}
                  </div>
                </template>
              </div>
              <div>
                <div class="flex pt-1">
                  <button class="btn btn-primary btn-sm px-3 mr-2" @click="getTenderDetails">
                    Get
                  </button>
                  <button class="btn btn-secondary btn-sm px-3" @click="resetForm">
                    Reset
                  </button>
                </div>
              </div>
            </div>

            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 lg:col-span-5">
                <div>
                  <label for="update-profile-form-6" class="form-label font-bold">Tender Name :&nbsp;</label><label
                    for="update-profile-form-6" class="form-label">
                    {{ pageData.tenderDetail.tender_name }}</label>
                </div>
                <div class="mt-2">
                  <label for="update-profile-form-6" class="form-label font-bold">Company Name :&nbsp;</label><label
                    for="update-profile-form-6" class="form-label">
                    {{ pageData.tenderDetail.tender_company?.firm_name }}</label>
                </div>

                <div class="mt-2">
                  <label for="update-profile-form-6" class="form-label font-bold">Tender Place :&nbsp;</label><label
                    for="update-profile-form-6" class="form-label">
                    {{ pageData.tenderDetail.location?.location_name }}</label>
                </div>
                <div class="mt-2">
                  <label for="update-profile-form-6" class="form-label font-bold">Partner :&nbsp;</label><label
                    for="update-profile-form-6" class="form-label">
                    {{ pageData.tenderDetail.partner?.firm_name }}</label>
                </div>
                <div class="mt-2">
                  <label for="update-profile-form-6" class="form-label font-bold">Partnership(%) :&nbsp;</label><label
                    for="update-profile-form-6" class="form-label">
                    {{ pageData.tenderDetail.partnership }}</label>
                </div>

                <div class="mt-2">
                  <label for="update-profile-form-6" class="form-label font-bold">Status :&nbsp;</label>
                  <span class="text-xs font-medium me-2 px-2.5 py-0.5 rounded" :class="pageData.tenderDetail.statusText
                      ? pageData.tenderDetail.statusText == 'Cancelled'
                        ? 'bg-red-100 text-red-800'
                        : pageData.tenderDetail.statusText == 'Won'
                          ? 'bg-blue-100 text-blue-800'
                          : pageData.tenderDetail.statusText == 'Active'
                            ? 'bg-green-100 text-green-800'
                            : 'bg-red-100 text-red-800'
                      : ''
                    ">{{ pageData.tenderDetail.statusText }}</span>
                </div>
              </div>
              <div class="col-span-12 lg:col-span-5">
                <div class="mt-2">
                  <label for="update-profile-form-6" class="form-label font-bold">Total Lots :&nbsp;</label><label
                    for="update-profile-form-6" class="form-label">
                    {{ pageData.tenderDetail.total_lots }}</label>
                </div>
                <div class="mt-2">
                  <label for="update-profile-form-6" class="form-label font-bold">Total Weight :&nbsp;</label><label
                    for="update-profile-form-6" class="form-label">
                    {{ pageData.tenderDetail.total_weight }}</label>
                </div>
                <div class="mt-2">
                  <label for="update-profile-form-6" class="form-label font-bold">Total Amount :&nbsp;</label><label
                    for="update-profile-form-6" class="form-label">
                    {{ pageData.tenderDetail.total_amount }}</label>
                </div>
                <div class="mt-2">
                  <label for="update-profile-form-6" class="form-label font-bold">Total Expense :&nbsp;</label><label
                    for="update-profile-form-6" class="form-label">
                    <a href="javascript:;" data-toggle="modal" data-target="#expense-detail-modal">{{
                      pageData.tenderDetail.total_expense }}</a></label>
                </div>
                <div class="mt-2">
                  <label for="update-profile-form-6" class="form-label font-bold">Tender Date :&nbsp;</label><label
                    for="update-profile-form-6" class="form-label">
                    {{ pageData.tenderDetail.tender_date }}
                  </label>
                </div>
                <div class="mt-2">
                  <label for="update-profile-form-6" class="form-label font-bold">Declare Date :&nbsp;</label><label
                    for="update-profile-form-6" class="form-label">
                    {{ pageData.tenderDetail.declare_date }}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap items-center justify-between px-5">
            <div class="grid grid-cols-12 gap-x-2 w-full">
              <div class="col-span-6 md:col-span-3 xl:col-span-3">
                <!-- <select v-model="formData.finance_company" class="w-full form-select py-1.5" :class="{
                  'border-theme-6': validate.finance_company.$error
                }">
                  <option v-for="(finance, index) in pageData.financeCompanyList" :key="index" :value="finance.id">
                    {{ finance.firm_name }}
                  </option>
                </select> -->
                <TomSelect v-model="formData.finance_company" :class="{
                  'border-theme-6': validate.finance_company.$error
                }" :options="{
  placeholder: ''
}">
                  <option value="0">Select Finance Company</option>
                  <option v-for="(finance, index) in pageData.financeCompanyList" :key="index" :value="finance.id">
                    {{ finance.firm_name }}
                  </option>
                </TomSelect>
                <template v-if="validate.finance_company.$error">
                  <div v-for="(error, index) in validate.finance_company.$errors" :key="index" class="text-theme-6 mt-2">
                    {{ error.$message }}
                  </div>
                </template>
              </div>
              <div class="col-span-6 md:col-span-3 xl:col-span-3">
                <input type="text" v-model="formData.invoice_amount" class="form-control input-padding" disabled
                  placeholder="Invoice Amount" :class="{
                    'border-theme-6': validate.invoice_amount.$error
                  }" />
                <template v-if="validate.invoice_amount.$error">
                  <div v-for="(error, index) in validate.invoice_amount.$errors" :key="index" class="text-theme-6 mt-2">
                    {{ error.$message }}
                  </div>
                </template>
              </div>
              <div class="col-span-6 md:col-span-3 xl:col-span-3">
                <input type="text" v-model="formData.interest" placeholder="Per Day Finance"
                  class="form-control input-padding" :class="{
                    'border-theme-6': validate.interest.$error
                  }" />
                <template v-if="validate.interest.$error">
                  <div v-for="(error, index) in validate.interest.$errors" :key="index" class="text-theme-6 mt-2">
                    {{ error.$message }}
                  </div>
                </template>
              </div>

              <div class="col-span-6 md:col-span-3 xl:col-span-3">
                <input type="text" v-model="formData.total_expense" placeholder="Total Import Expense" disabled
                  class="form-control input-padding" :class="{
                    'border-theme-6': validate.total_expense.$error
                  }" />
                <template v-if="validate.total_expense.$error">
                  <div v-for="(error, index) in validate.total_expense.$errors" :key="index" class="text-theme-6 mt-2">
                    {{ error.$message }}
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="grid grid-cols-12 gap-6">
            <div class="col-span-12 p-5">
              <!-- BEGIN: Form Layout -->
              <p class="font-bold text-lg pb-2">Import Expense</p>
              <div class="">
                <table class="border-2 pt-5 min-w-full table-auto">
                  <thead>
                    <tr class="border">
                      <th style="width:35%">Expense Type</th>
                      <th style="width:35%">Amount</th>
                      <th style="width:20%">File</th>
                      <th style="width:10%" class="text-center">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody class="border-2">
                    <tr class="" v-for="(item, index) in formData.expense_details" :key="index">
                      <td class="whitespace-nowrap w-24">
                        <TomSelect v-model="item.expense_name" :options="{
                          placeholder: ''
                        }">
                          <option value="0">Select Expense Type</option>
                          <option v-for="(expense, inx) in pageData.expenseList" :key="inx" :value="expense.id">{{
                            expense.expense_name }}
                          </option>
                        </TomSelect>
                      </td>
                      <td class="whitespace-nowrap w-24" style="padding: 2px;">
                        <input type="text" v-model="item.amount" class="form-control rounded-none w-full"
                          placeholder="Amount" aria-describedby="input-group-3" @keypress="$h.preventInvalidInput" />
                      </td>
                      <td class="pl-24">
                        <button class="btn btn-outline-primary btn-xs py-1 px-1" @click="clearDropzone('file', index)">
                          <UploadIcon class="h-3 w-3" />
                        </button>
                        <a v-if="formData.expense_details[index].file != ''"
                          class="ml-2 btn btn-outline-primary btn-xs py-1 px-1" target="_blank"
                          :href="baseURL + formData.expense_details[index].file">
                          <ImageIcon class="h-3 w-3" />
                        </a>
                      </td>
                      <td class="whitespace-nowrap text-center" v-if="index != 0">
                        <button class="btn btn-danger btn-sm p-1" @click="deleteRow(index)">
                          <Trash2Icon class="h-4 w-4" />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="mt-4">
                <button class="btn btn-secondary btn-sm" @click="addRow">
                  <PlusIcon class="h-4 w-4 mr-2" /> Add
                </button>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap items-center justify-between px-5 pb-5">
            <div class="grid grid-cols-12 gap-x-2 w-full">
              <div class="col-span-6 md:col-span-3 xl:col-span-3">
                <input type="text" v-model="formData.stock_no" placeholder="" class="form-control py-1" />
                <!-- <template v-if="validate.stock_no.$error">
                  <div v-for="(error, index) in validate.stock_no.$errors" :key="index" class="text-theme-6 mt-2">
                    {{ error.$message }}
                  </div>
                </template> -->
              </div>
              <div class="">
                <button class="btn btn-primary btn-sm px-3" @click="getLotDetails">
                  Get / Add
                </button>
              </div>
            </div>
          </div>
          <div class="grid grid-cols-12 gap-6">
            <div class="col-span-12 px-5 pb-5">
              <!-- BEGIN: Form Layout -->
              <p class="font-bold text-lg pb-2">Lot Details</p>
              <div>
                <label for="update-profile-form-6" class="form-label font-bold">Total Lots :&nbsp;</label><label
                  for="update-profile-form-6" class="form-label">
                  {{ TotalLotNo }}</label>
                <label for="update-profile-form-6" class="form-label font-bold ml-5">Total Weight :&nbsp;</label><label
                  for="update-profile-form-6" class="form-label">
                  {{ TotalWeight }}</label>
                <label for="update-profile-form-6" class="form-label font-bold ml-5">Total Amount :&nbsp;</label><label
                  for="update-profile-form-6" class="form-label">
                  {{ TotalAmount }}</label>
              </div>

              <div class="overflow-auto">
                <table class="border-2 pt-5 table-auto w-full">
                  <thead class="">
                    <tr class="border">
                      <th class="py-2">SR.NO</th>
                      <th class="whitespace-nowrap">
                        Lot No
                      </th>
                      <th>Weight</th>
                      <th>Result</th>
                      <th>Color</th>
                      <th>Purity</th>
                      <th>Blue Uv</th>
                      <th>Brown</th>
                      <th>Comment</th>
                      <th>Flow</th>
                      <th>Tension</th>
                      <th>Eyeclc-R</th>
                      <th>Eyeclc-F</th>
                      <th>Center</th>
                      <th>Price/ct</th>
                      <th class="whitespace-nowrap">Total Price</th>
                      <th>Video</th>
                      <th>Cap</th>
                      <th>Image</th>
                      <th class="text-center">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody class="border-2">
                    <tr class="" v-for="(item, index) in formData.lot_details" :key="index">
                      <td class="text-center border-2">
                        {{ index + 1 }}
                      </td>
                      <td class="whitespace-nowrap w-24">
                        <input type="text" v-model="item.lot_no" class="form-control rounded-none w-20 py-1"
                          placeholder="" aria-describedby="input-group-3" />
                      </td>
                      <td class="whitespace-nowrap w-24">
                        <input type="text" v-model="item.weight" class="form-control rounded-none w-20 py-1"
                          placeholder="" @input="TotalPrice(index)" aria-describedby="input-group-3"
                          @keypress="$h.preventInvalidInput" />
                      </td>
                      <td class="whitespace-nowrap w-24">
                        <select v-model="item.result" class="w-20 form-select py-1">
                          <option value="no_result" label="???">???</option>
                          <option v-for="(result, index) in pageData.colorList" :key="index" :value="result.id">{{
                            result.color_name }}
                          </option>
                        </select>
                      </td>
                      <td class="whitespace-nowrap w-24">
                        <select v-model="item.color" class="w-20 form-select py-1">
                          <option value="no_result" label="??">??</option>
                          <option v-for="(color, index) in pageData.colorList" :key="index" :value="color.id">{{
                            color.color_name }}
                          </option>
                        </select>
                      </td>
                      <td class="whitespace-nowrap w-24">
                        <select v-model="item.purity" class="w-20 form-select py-1">
                          <option value="no_result" label="??">??</option>
                          <option v-for="(purity, index) in pageData.purityList" :key="index" :value="purity.id">{{
                            purity.purity_name }}
                          </option>
                        </select>
                      </td>
                      <td class="whitespace-nowrap w-24">
                        <select v-model="item.blue_uv" class="w-20 form-select py-1">
                          <option v-for="(blueUv, index) in pageData.blueUvList" :key="index" :value="blueUv">{{ blueUv }}
                          </option>
                        </select>
                      </td>
                      <td class="whitespace-nowrap w-24">
                        <select
                          v-model="item.brown"
                          class="w-20 form-select py-1"
                        >
                          <option
                            v-for="(brown, index) in pageData.brownList"
                            :key="index"
                            :value="brown.id"
                            >{{ brown.brown_name }}
                          </option>
                        </select>
                      </td>
                      <td class="whitespace-nowrap w-24">
                        <select
                          v-model="item.comment"
                          class="w-20 form-select py-1"
                        >
                          <option
                            v-for="(comment, index) in pageData.commentList"
                            :key="index"
                            :value="comment.id"
                            >{{ comment.comment_name }}
                          </option>
                        </select>
                      </td>
                      <td class="whitespace-nowrap w-24">
                        <select
                          v-model="item.flow"
                          class="w-20 form-select py-1"
                        >
                          <option
                            v-for="(flow, index) in pageData.flowList"
                            :key="index"
                            :value="flow.id"
                            >{{ flow.fluerocent_name }}
                          </option>
                        </select>
                      </td>
                      <td class="whitespace-nowrap w-24">
                        <select v-model="item.tension" class="w-20 form-select py-1">
                          <option v-for="(tension, index) in pageData.tensionList" :key="index"
                            :value="tension.id">{{ tension.tension_name }}
                          </option>
                        </select>
                      </td>
                      <td class="whitespace-nowrap w-24">
                        <select v-model="item.eyeclc_r" class="w-20 form-select py-1">
                          <!-- <option value="no_result" label="??">??</option> -->
                          <option v-for="(eyeclc_r, index) in pageData.colorList" :key="index" :value="eyeclc_r.id">{{
                            eyeclc_r.color_name }}
                          </option>
                        </select>
                      </td>
                      <td class="whitespace-nowrap w-24">
                        <select v-model="item.eyeclc_f" class="w-20 form-select py-1">
                          <!-- <option value="no_result" label="??">??</option> -->
                          <option v-for="(eyeclc_f, index) in pageData.colorList" :key="index" :value="eyeclc_f.id">{{
                            eyeclc_f.color_name }}
                          </option>
                        </select>
                      </td>
                      <td class="whitespace-nowrap w-24">
                        <select v-model="item.center" class="w-20 form-select py-1">
                          <option v-for="(center, index) in pageData.purityList" :key="index" :value="center.id">{{
                            center.purity_name }}
                          </option>
                        </select>
                      </td>
                      <td class="whitespace-nowrap w-24">
                        <input type="text" v-model="item.price_ct" @input="TotalPrice(index)"
                          class="form-control rounded-none w-20 py-1" placeholder="" aria-describedby="input-group-3"
                          @keypress="$h.preventInvalidInput" />
                      </td>
                      <td class="whitespace-nowrap w-24">
                        <input type="text" v-model="item.total_price" disabled class="form-control rounded-none w-20 py-1"
                          placeholder="" aria-describedby="input-group-3" @keypress="$h.preventInvalidInput" />
                      </td>
                      <td class="pl-8">
                        <div class="w-16">
                          <button class="btn btn-outline-primary btn-xs py-1 px-1" @click="clearDropzone('video', index)">
                            <UploadIcon class="h-3 w-3" />
                          </button>
                          <a v-if="formData.lot_details[index].video != ''"
                            class="ml-2 btn btn-outline-primary btn-xs py-1 px-1" target="_blank"
                            :href="baseURL + formData.lot_details[index].video">
                            <ImageIcon class="h-3 w-3" />
                          </a>
                        </div>
                      </td>
                      <td class="pl-8">
                        <div class="w-16">
                          <button class="btn btn-outline-primary btn-xs py-1 px-1" @click="clearDropzone('cap', index)">
                            <UploadIcon class="h-3 w-3" />
                          </button>
                          <a v-if="formData.lot_details[index].cap != ''"
                            class="ml-2 btn btn-outline-primary btn-xs py-1 px-1" target="_blank"
                            :href="baseURL + formData.lot_details[index].cap">
                            <ImageIcon class="h-3 w-3" />
                          </a>
                        </div>
                      </td>
                      <td class="pl-8">
                        <div class="w-16">
                          <button class="btn btn-outline-primary btn-xs py-1 px-1" @click="clearDropzone('image', index)">
                            <UploadIcon class="h-3 w-3" />
                          </button>
                          <a v-if="formData.lot_details[index].image != ''"
                            class="ml-2 btn btn-outline-primary btn-xs py-1 px-1" target="_blank"
                            :href="baseURL + formData.lot_details[index].image">
                            <ImageIcon class="h-3 w-3" />
                          </a>
                        </div>
                      </td>
                      <td class="whitespace-nowrap text-center" style="padding: 2px;" v-if="index != 0">
                        <button class="btn btn-danger btn-sm p-1" @click="deleteLotRow(index)">
                          <Trash2Icon class="h-4 w-4" />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="pb-5 px-5 text-right">
            <button type="button" @click="submitForm" class="btn btn-primary w-24 ml-2">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
    <div id="upload-image-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <a data-dismiss="modal" href="javascript:;">
            <XIcon class="w-8 h-8 text-gray-500" />
          </a>
          <div class="modal-body p-10">
            <!-- <div id="vertical-form"> -->
            <div class="flex flex-col justify-between mt-5">
              <!-- BEGIN: Data List -->
              <div class="intro-y overflow-auto">
                <div class="flex items-center">
                  <h2 class="intro-y text-lg font-medium mr-5">Upload Image</h2>
                </div>
              </div>
              <div class="grid grid-cols-12 gap-4">
                <div class="intro-y col-span-12">
                  <!-- BEGIN: Dropzone -->
                  <div class="dropzone" id="stock-dropzone"></div>
                  <!-- END: Dropzone -->
                </div>
              </div>
              <!-- END: Data List -->
            </div>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="success-notification-content" class="toastify-content hidden flex">
    <CheckCircleIcon class="text-theme-9" />
    <div class="ml-4 mr-4">
      <div class="font-medium">Details saved successfully!</div>
      <div class="text-gray-600 mt-1"></div>
    </div>
  </div>
  <!-- END: Success Notification Content -->
  <!-- BEGIN: Failed Notification Content -->
  <div id="failed-notification-content" class="toastify-content hidden flex">
    <XCircleIcon class="text-theme-6" />
    <div class="ml-4 mr-4">
      <div class="font-medium">Something went wrong!</div>
      <div class="text-gray-600 mt-1">
        Please check the fileld form.
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  reactive,
  ref,
  toRef,
  toRefs,
  watch
} from 'vue'
import axios from 'axios'
import datetime from 'vuejs-datetimepicker'
import { useRoute } from 'vue-router'

import { BASE_URL } from '@/constants/config.js'
import { UploadIcon } from '@zhuowenli/vue-feather-icons'
import Swal from 'sweetalert2'
import Dropzone from 'dropzone'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { helper as $h } from '@/utils/helper'
import Toastify from 'toastify-js'
import router from '@/router'

export default defineComponent({
  components: {
    datetime
  },

  setup() {
    const route = useRoute()
    const tabulator = ref()
    const baseURL = ref(BASE_URL)
    const stockDropzone = ref(null)

    const swal = inject('$swal')

    const pageData = reactive({
      tenderDetail: [],
      tensionList: [],
      purityList: [],
      brownList: [],
      flowList: [],
      commentList: [],
      tender_list: [],
      financeCompanyList: [],
      blueUvList: Array.from({ length: 76 }, (_, i) =>
        i.toString().padStart(3, '0')
      ),
      expenseList: [],
      colorList: [],
      errors: [],

      modelIndex: 0,
      modelFileType: '',
      token: localStorage.getItem('token')
    })
    const errors = toRef(pageData, 'errors')

    const formData = reactive({
      tender_id: '',
      finance_company: '',
      invoice_amount: '',
      interest: '',
      total_expense: '',
      stock_no: '',
      expense_details: [
        {
          expense_name: '',
          amount: '',
          file: ''
        }
      ],
      lot_details: [
        {
          lot_no: '',
          weight: '',
          result: 'no_result',
          color: 'no_result',
          purity: 'no_result',
          blue_uv: '',
          brown: '',
          comment: '',
          flow: '',
          tension: '',
          eyeclc_r: '',
          eyeclc_f: '',
          center: '',
          price_ct: '',
          total_price: '',
          video: '',
          cap: '',
          image: ''
        }
      ],
      total_lots: '',
      per_ct_price: '',
      total_weight: '',
      total_amount: ''
    })

    const rules = {
      invoice_amount: {
        required
      },
      finance_company: {
        required
      },
      interest: {
        required
      },
      total_expense: {
        required
      },
      tender_id: {
        required
      }
    }
    const validate = useVuelidate(rules, toRefs(formData))

    const resetForm = () => {
      ; (pageData.tenderDetail = []),
        (formData.tender_id = ''),
        (formData.finance_company = ''),
        (formData.invoice_amount = ''),
        (formData.interest = ''),
        (formData.total_expense = ''),
        (formData.stock_no = ''),
        (formData.expense_details = [
          {
            expense_name: '',
            amount: '',
            file: ''
          }
        ]),
        (formData.lot_details = [
          {
            lot_no: '',
            weight: '',
            result: 'no_result',
            color: 'no_result',
            purity: 'no_result',
            blue_uv: '',
            brown: '',
            comment: '',
            flow: '',
            tension: '',
            eyeclc_r: '',
            eyeclc_f: '',
            center: '',
            price_ct: '',
            total_price: '',
            video: '',
            cap: '',
            image: ''
          }
        ])
    }

    const TotalPrice = index => {
      const item = formData.lot_details[index]
      item.total_price = item.weight * item.price_ct
    }

    const TotalAmount = computed(() => {
      let total = 0
      for (const item of formData.lot_details) {
        total += parseFloat(item.total_price) || 0
      }
      return total
    })

    watch(TotalAmount, (newValue, oldValue) => {
      formData.total_amount = newValue
    })

    const TotalExpense = computed(() => {
      let total = 0
      for (const item of formData.expense_details) {
        total += parseFloat(item.amount) || 0
      }
      return total
    })

    watch(TotalExpense, (newValue, oldValue) => {
      formData.total_expense = newValue
    })

    const TotalInvoiceAmount = computed(() => {
      let total = 0
      for (const item of formData.lot_details) {
        total += parseFloat(item.total_price) || 0
      }
      return total
    })

    watch(TotalInvoiceAmount, (newValue, oldValue) => {
      formData.invoice_amount = newValue
    })

    const TotalWeight = computed(() => {
      let total = 0
      for (const item of formData.lot_details) {
        total += parseFloat(item.weight) || 0
      }
      return total
    })

    watch(TotalWeight, (newValue, oldValue) => {
      formData.total_weight = newValue
    })

    const TotalLotNo = computed(() => {
      return formData.lot_details.length || 0
    })

    watch(TotalLotNo, (newValue, oldValue) => {
      formData.total_lots = newValue
    })

    const TotalAvg = computed(() => {
      formData.avg =
        $h.myFloat(formData.total_amount) / $h.myFloat(formData.total_weight)
    })

    watch(TotalAvg, (newValue, oldValue) => {
      formData.per_ct_price = newValue
    })

    const clearDropzone = (type, inx) => {
      pageData.modelFileType = type
      pageData.modelIndex = inx
      cash('#upload-image-modal').modal('show')
    }

    const addRow = () => {
      formData.expense_details.push({
        // expense_name: String(pageData.expenseList[0].id),
        expense_name: '',
        amount: '',
        file: ''
      })
    }

    const deleteRow = index => {
      swal({
        title: 'Are you sure?',
        text: 'You are about to Remove' + ' Record!',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirm',
        showLoaderOnConfirm: true,
        cancelButtonText: 'Cancel',
        preConfirm: () => {
          return true
        },

        allowOutsideClick: () => !Swal.isLoading()
      }).then(result => {
        if (result.isConfirmed) {
          formData.expense_details.splice(index, 1)
        }
      })
    }

    function get_expense_list() {
      axios({
        url: 'api/master/dp_expense',
        method: 'post',
        baseURL: BASE_URL,
        data: { type: window.btoa(2) },
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            Accept: 'application/json'
          }
        }
      }).then(res => {
        pageData.expenseList = res.data.items
      })
    }

    const getTenderDetails = async () => {
      const params = {
        id: btoa(formData.tender_id)
      }
      let promise = axios({
        url: 'api/tender/view',
        method: 'POST',
        params: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            Accept: 'application/json'
          }
        }
      })
      return promise
        .then(result => result.data)
        .then(data => {
          pageData.tenderDetail = data.item
        })
        .catch(_error => { })
    }
    const getLotDetails = async () => {
      const params = {
        stock_no: formData.stock_no,
        tender_id: formData.tender_id
      }
      let promise = axios({
        url: 'api/tender/search',
        method: 'POST',
        params: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            Accept: 'application/json'
          }
        }
      })
      return promise
        .then(result => result.data)
        .then(data => {
          formData.lot_details = data.items
        })
        .catch(_error => { })
    }

    const getFinanceDetails = async () => {
      let promise = axios({
        url: 'api/master/finance_company_list',
        method: 'POST',
        baseURL: BASE_URL,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            Accept: 'application/json'
          }
        }
      })
      return promise
        .then(result => result.data)
        .then(data => {
          pageData.financeCompanyList = data.items
        })
        .catch(_error => { })
    }

    function getTenderList() {
      axios({
        url: 'api/tender/list',
        method: 'post',
        baseURL: BASE_URL,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            Accept: 'application/json'
          }
        }
      }).then(res => {
        pageData.tender_list = res.data.items
      })
    }

    function submitForm() {
      validate.value.$touch()
      if (validate.value.$invalid) {
        Toastify({
          node: cash('#failed-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
      } else {
        const params = {
          tender_id: formData.tender_id,
          finance_company: formData.finance_company,
          invoice_amount: formData.invoice_amount,
          interest: formData.interest,
          total_expense: formData.total_expense,
          expense_details: formData.expense_details,
          lot_details: formData.lot_details,
          total_lots: TotalLotNo.value,
          per_ct_price: TotalAvg.value,
          total_weight: TotalWeight.value,
          total_amount: TotalAmount.value
        }
        axios({
          url: 'api/tender-winning/create',
          method: 'post',
          baseURL: BASE_URL,
          data: params,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { Accept: 'application/json' } }
        })
          .then(() => {
            router.push({ name: 'tender-list' })
            Toastify({
              node: cash('#success-notification-content')
                .clone()
                .removeClass('hidden')[0],
              duration: 3000,
              newWindow: true,
              close: true,
              gravity: 'top',
              position: 'right',
              stopOnFocus: true
            }).showToast()
            validate.value.$reset()
            resetForm()
            initTabulator()
          })
          .catch(err => {
            // errors.value = err.response.data.errors
            setTimeout(() => {
              pageData.errors = []
            }, 5000)
          })
      }
    }

    onMounted(() => {
      if (route.params.id) {
        getTenderDetails(route.params.id)
      }
      getFinanceDetails()
      getTenderList()
      get_expense_list()
      get_parameters()

      stockDropzone.value = new Dropzone('#stock-dropzone', {
        url:
          `${BASE_URL}api/tender-winning/upload?file_type=` +
          pageData.modelFileType,
        thumbnailWidth: 150,
        addRemoveLinks: true,
        maxFiles: 1,
        headers: {
          Authorization: 'Bearer ' + window.localStorage.getItem('token')
        },
        init: function () {
          this.on('error', function (file, errorMessage) {
            this.removeFile(file)
            swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'No more files please!'
            })
          })

          this.on('success', function (file, response) {
            const packetIndex = pageData.modelIndex
            const modelFileType = pageData.modelFileType

            if (modelFileType == 'image') {
              formData.lot_details[packetIndex].image = response.file_name
              console.log(formData.lot_details[packetIndex].image)
            } else if (modelFileType == 'video') {
              formData.lot_details[packetIndex].video = response.file_name
            } else if (modelFileType == 'cap') {
              formData.lot_details[packetIndex].cap = response.file_name
            } else if (modelFileType == 'file') {
              formData.expense_details[packetIndex].file = response.file_name
            }
            cash('#upload-image-modal').modal('hide')

            // purchase.packet_details[packetIndex].url = response.path;

            swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'File Uploaded'
            })
            this.removeFile(file)
          })
        }
      })
    })

    function get_parameters() {
      axios({
        url: 'api/tender/params',
        method: 'post',
        baseURL: BASE_URL,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            Accept: 'application/json'
          }
        }
      }).then(res => {
        pageData.colorList = res.data.color
        pageData.brownList = res.data.brown
        pageData.tensionList = res.data.tension
        pageData.flowList = res.data.fluerocent
        pageData.purityList = res.data.purity
        pageData.commentList = res.data.comment
      })
    }
    return {
      pageData,
      tabulator,
      getTenderDetails,
      getFinanceDetails,
      getTenderList,
      baseURL,
      formData,
      addRow,
      get_expense_list,
      deleteRow,
      clearDropzone,
      stockDropzone,
      TotalPrice,
      getLotDetails,
      submitForm,
      TotalAvg,
      TotalLotNo,
      TotalWeight,
      TotalAmount,
      TotalExpense,
      validate,
      errors,
      resetForm
    }
  }
})
</script>
<style scoped>
.text-green-800 {
  color: #03543f;
}

.bg-green-100 {
  background-color: #def7ec;
}

.bg-red-100 {
  background-color: #fde8e8;
}

.text-red-800 {
  color: #9b1c1c;
}

.text-yellow-800 {
  color: #723b13;
}

.bg-yellow-100 {
  background-color: #fdf6b2;
}

.text-blue-800 {
  color: #1e429f;
}

.bg-blue-100 {
  background-color: #e1effe;
}

a:link {
  text-decoration: underline;
}

.btn.btn-outline-primary.btn-xs.py-1:hover {
  background-color: transparent;
}

.input-padding {
  padding-top: 9px;
  padding-bottom: 9px;
}
.overflow-auto {
  scrollbar-width: thin;
}

</style>
