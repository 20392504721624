<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Company Create</h2>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div id="input" class="p-5">
            <div class="preview">
              <div>
                <label for="regular-form-2" class="form-label">First Name & Last Name</label>
                <div class="sm:grid grid-cols-3 gap-2">
                  <div>
                    <div>
                      <input v-model="party.first_name" id="regular-form-1" type="text" class="form-control"
                        placeholder="First Name" />
                    </div>
                    <span class="text-primary-3" v-if="errors.first_name">{{
                      errors.first_name[0]
                    }}</span>
                  </div>
                  <div>
                    <div>
                      <input v-model="party.last_name" id="regular-form-1" type="text" class="form-control"
                        placeholder="Last Name" />
                    </div>
                    <span class="text-primary-3" v-if="errors.last_name">{{
                      errors.last_name[0]
                    }}</span>
                  </div>
                  <div>
                    <div>
                      <input v-model="party.short_name" id="regular-form-1" type="text" class="form-control"
                        placeholder="Short Name" />
                    </div>
                    <span class="text-primary-3" v-if="errors.short_name">{{
                      errors.short_name[0]
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <label class="form-label">Email Id & Mobile No</label>
                <div class="sm:grid grid-cols-2 gap-2">
                  <div>
                    <div class="input-group">
                      <input v-model="party.email" id="regular-form-1" type="email" class="form-control"
                        placeholder="Email" />
                    </div>
                    <span class="text-primary-3" v-if="errors.email">{{
                      errors.email[0]
                    }}</span>
                  </div>
                  <div class="mt-2 sm:mt-0">
                    <div class="input-group">
                      <input type="tel" v-model="party.mobile_no" class="form-control" placeholder="Mobile No"
                        aria-describedby="input-group-3" />
                    </div>
                    <span class="text-primary-3" v-if="errors.mobile_no">{{
                      errors.mobile_no[0]
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <label class="form-label">Firm Name & Location</label>
                <div class="sm:grid grid-cols-2 gap-2">
                  <div>
                    <div class="input-group">
                      <input type="tel" v-model="party.firm_name" class="form-control" placeholder="Firm Name"
                        aria-describedby="input-group-3" />
                    </div>
                    <span class="text-primary-3" v-if="errors.firm_name">{{
                      errors.firm_name[0]
                    }}</span>
                  </div>
                  <div>
                    <div class="input-group">
                      <TomSelect v-model="party.location_id" :options="{
                        placeholder: 'Select your location'
                      }" class="w-full">
                        <option value="0">Select Location</option>
                        <option v-for="(location, index) in pageData.locationList" :key="index" :value="location.id">{{
                          location.location_name }}
                        </option>
                      </TomSelect>
                    </div>
                    <span class="text-primary-3" v-if="errors.location_id">{{
                      errors.location_id[0]
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <label class="form-label">Interest(%)(Per Day)</label>
                <div class="sm:grid grid-cols-2 gap-2">
                  <div>
                    <div class="input-group">
                      <input type="tel" v-model="party.interest" class="form-control" placeholder="Interest"
                        aria-describedby="input-group-3" />
                    </div>
                    <span class="text-primary-3" v-if="errors.interest">{{
                      errors.interest[0]
                    }}</span>
                  </div>
                  <div class="flex items-center">
                    <input type="checkbox" v-model="party.import_expense"
                      class="form-checkbox h-5 w-5 text-red-600 border-gray-300 rounded cursor-pointer focus:outline-none focus:ring-2 focus:ring-red-500" />
                    <span class="ml-2 text-gray-700">Do you want to add import expense ?</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-right" v-if="party.import_expense == false">
              <router-link :to="{ name: 'finance-company-details' }" class="btn btn-outline-secondary w-24 mr-1">
                Cancel
              </router-link>
              <button type="button" @click="SaveParty" class="btn btn-primary w-24">
                Save
              </button>
            </div>
          </div>
        </div>
        <!-- END: Input -->
      </div>
    </div>
  </div>
  <div class="grid grid-cols-12 gap-6 mt-5" v-if="party.import_expense == true">
    <div class="intro-y box col-span-12 p-5">
      <!-- BEGIN: Form Layout -->
      <p class="font-bold text-lg pb-2">Expense Details</p>
      <div class="">
        <table class="border-2 pt-5 min-w-full table-auto">
          <thead>
            <tr class="border">
              <th style="width:10%">SR.NO</th>
              <th style="width:40%">Expense Head</th>
              <th style="width:40%">Amount</th>
              <th style="width:10%" class="text-center">Action</th>
            </tr>
          </thead>
          <tbody class="border-2">
            <tr class="" v-for="(item, index) in party.expense_details" :key="index">
              <td class="whitespace-nowrap border-b text-center">{{ index + 1 }}</td>

              <td class="whitespace-nowrap w-24">
                <select v-model="item.expense_head" class="w-full form-select py-1">
                  <option v-for="(expense, index) in pageData.expenseList" :key="index" :value="expense.id">{{
                    expense.expense_name }}
                  </option>
                </select>
              </td>
              <td class="whitespace-nowrap w-24">
                <input type="text" v-model="item.amount" class="form-control rounded-none w-full py-1" placeholder="Amount"
                  aria-describedby="input-group-3" @keypress="$h.preventInvalidInput" />
              </td>
              <td class="whitespace-nowrap text-center" style="padding: 2px;" v-if="index != 0">
                <button class="btn btn-danger btn-sm p-1" @click="deleteRow(index)">
                  <Trash2Icon class="h-4 w-4" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- END: Form Layout -->
      <div class="flex justify-between items-center mt-4">
        <div class="">
          <div>
            <button class="btn btn-primary btn-sm p-2" @click="addRow">
              <PlusIcon class="h-4 w-4 mr-2" /> Add
            </button>
          </div>
        </div>
        <div class="text-right">
          <router-link :to="{ name: 'finance-company-details' }" class="btn btn-outline-secondary w-24 mr-1">
            Cancel
          </router-link>
          <button type="button" @click="SaveParty" class="btn btn-primary w-24">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>

  <div id="success-notification-content" class="toastify-content hidden flex">
    <CheckCircleIcon class="text-theme-9" />
    <div class="ml-4 mr-4">
      <div class="font-medium">Details saved successfully!</div>
      <div class="text-gray-600 mt-1"></div>
    </div>
  </div>
  <!-- END: Success Notification Content -->
  <!-- BEGIN: Failed Notification Content -->
  <div id="failed-notification-content" class="toastify-content hidden flex">
    <XCircleIcon class="text-theme-6" />
    <div class="ml-4 mr-4">
      <div class="font-medium">Something went wrong!</div>
      <div class="text-gray-600 mt-1">
        Please check the fileld form.
      </div>
    </div>
  </div>
  <!-- END: Failed Notification Content -->
</template>

<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import router from '@/router'
import { useRoute } from 'vue-router'
import axios from 'axios'
import { defineComponent, onMounted, reactive, ref, toRef, inject } from 'vue'
import toastify from 'toastify-js'
export default defineComponent({
  setup() {
    const swal = inject('$swal')
    const party = reactive({
      id: '',
      first_name: '',
      last_name: '',
      short_name: '',
      email: '',
      firm_name: '',
      location_id: '',
      mobile_no: '',
      interest: '',
      import_expense: false,
      expense_details: [
        {
          expense_head: '',
          amount: ''
        }
      ]
    })
    const pageData = reactive({
      errors: [],
      locationList: [],
      expenseList: []
    })

    const addRow = () => {
      party.expense_details.push({
        expense_head: String(pageData.expenseList[0].id),
        amount: ''
      })
    }

    const deleteRow = index => {
      swal({
        title: 'Are you sure?',
        text: 'You are about to Remove' + ' Record!',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirm',
        showLoaderOnConfirm: true,
        cancelButtonText: 'Cancel',
        preConfirm: () => {
          return true
        },

        allowOutsideClick: () => !Swal.isLoading()
      }).then(result => {
        if (result.isConfirmed) {
          party.expense_details.splice(index, 1)
        }
      })
    }

    const errors = toRef(pageData, 'errors')
    const route = useRoute()
    onMounted(() => {
      if (route.params.id) {
        party.id = route.params.id
        getParty()
      }
      get_location_list()
      get_expense_list()
    })
    function SaveParty() {
      if (route.params.id) {
        axios({
          url: 'api/master/finance_company_update',
          method: 'post',
          baseURL: BASE_URL,
          data: party,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { Accept: 'application/json' } }
        }).then(res => {
          toastify({
            node: cash('#success-notification-content')
              .clone()
              .removeClass('hidden')[0],
            duration: 3000,
            newWindow: true,
            close: true,
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
          router.push({ name: 'finance-company-details' })
        })
      } else {
        axios({
          url: 'api/master/finance_company_create',
          method: 'post',
          baseURL: BASE_URL,
          data: party,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { Accept: 'application/json' } }
        })
          .then(() => {
            toastify({
              node: cash('#success-notification-content')
                .clone()
                .removeClass('hidden')[0],
              duration: 3000,
              newWindow: true,
              close: true,
              gravity: 'top',
              position: 'right',
              stopOnFocus: true
            }).showToast()
            router.push({ name: 'finance-company-details' })
          })
          .catch(err => {
            errors.value = err.response.data.errors
            setTimeout(() => {
              pageData.errors = []
            }, 5000)
          })
      }
    }

    function get_location_list() {
      axios({
        url: 'api/master/dp_location',
        method: 'post',
        baseURL: BASE_URL,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            Accept: 'application/json'
          }
        }
      }).then(res => {
        pageData.locationList = res.data.items
      })
    }
    function get_expense_list() {
      axios({
        url: 'api/master/dp_expense',
        method: 'post',
        baseURL: BASE_URL,
        data: { type: window.btoa(2) },
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            Accept: 'application/json'
          }
        }
      }).then(res => {
        pageData.expenseList = res.data.items
        if (party.id == '' || !party.id) {
          party.expense_details[0].expense_head = String(
            pageData.expenseList[0].id
          )
        }
      })
    }
    function getParty() {
      const params = {
        id: route.params.id
      }
      axios({
        url: 'api/master/finance_company_edit',
        method: 'post',
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { Accept: 'application/json' } }
      }).then(res => {
        party.first_name = res.data.item.first_name
        party.last_name = res.data.item.last_name
        party.short_name = res.data.item.short_name
        party.email = res.data.item.email
        party.mobile_no = res.data.item.mobile_no
        party.expense_details = res.data.item.expense_items
        party.firm_name = res.data.item.firm_name
        party.import_expense = res.data.item.import_expense
        party.location_id = String(res.data.item.location_id)
        party.interest = res.data.item.interest
      })
    }
    return {
      party,
      pageData,
      errors,
      SaveParty,
      addRow,
      deleteRow
    }
  }
})
</script>
