<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Rough Tender</h2>
      <div>
        <button class="btn btn-primary" @click="create_tender_winning">
          New Winning
        </button>
        <button class="btn btn-primary ml-2" @click="open_tender_modal">
          New Tender
        </button>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-8">
      <div class="flex flex-wrap items-center justify-between">
        <div class="grid grid-cols-12 gap-x-2 w-full">
          <div class="col-span-6 md:col-span-3 xl:col-span-3">
            <div class="">
              <input
                type="text"
                id="update-profile-form-13"
                class="form-control"
                placeholder="Tender Name"
                v-model="filters.tender_name"
              />
            </div>
          </div>
          <div class="col-span-6 md:col-span-3 xl:col-span-3">
            <TomSelect
              v-model="filters.company_id"
              :options="{
                placeholder: 'Select Company',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Company'
                  }
                }
              }"
              class="w-full"
            >
              <option value="0">Select Company</option>
              <option
                v-for="(stone, index) in screenData.parties"
                :key="index"
                :value="stone.id"
                >{{ stone.firm_name }}
              </option>
            </TomSelect>
          </div>

          <div class="col-span-6 md:col-span-3 xl:col-span-3">
            <div>
              <Litepicker
                v-model="filters.tender_date"
                :options="{
                  singleMode: false,
                  autoApply: false,
                  format: 'YYYY-MM-DD',
                  showWeekNumbers: true,
                  dropdowns: {
                    minYear: 1990,
                    maxYear: null,
                    months: true,
                    years: true
                  },
                }"
                class="form-control pl-12"
              />
            </div>
          </div>
          <div class="col-span-2 md:col-span-2 xl:col-span-1">
            <div class="flex">
              <button
                id="tabulator-html-filter-reset"
                type="button"
                class="btn btn-secondary w-full mt-2 sm:mt-0 sm:ml-2"
                @click="onResetFilter"
              >
                Reset
              </button>
            </div>
          </div>
          <div class="col-span-2 md:col-span-2 xl:col-span-1">
            <div class="flex">
              <button
                id="tabulator-html-filter-go"
                type="button"
                class="btn btn-primary w-full ml-2"
                tabindex="5"
                @click="initTabulator()"
              >
                Go
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div id="tabulator" ref="tableRef" class="mt-5"></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <div
      id="new-rough-tender-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xxxl">
        <div class="modal-content">
          <a data-dismiss="modal" href="javascript:;">
            <XIcon class="w-8 h-8 text-gray-500" />
          </a>
          <div class="border-b pb-3">
            <p class="font-bold text-xl pt-5 ml-28">Add New Tender</p>
          </div>

          <div class="modal-body modal-body-w px-10 pt-5 pb-10">
            <!-- <div id="vertical-form"> -->

            <div class="flex flex-col justify-between">
              <!-- BEGIN: Data List -->
              <div>
                <!-- BEGIN: Wizard Layout -->

                <div class="intro-y">
                  <div
                    class="wizard flex flex-col lg:flex-row justify-center px-5 sm:px-20"
                  >
                    <div
                      class="intro-x lg:text-center flex items-center lg:block flex-1 z-10"
                    >
                      <button
                        :class="[
                          'w-10 h-10 rounded-full btn',
                          currentStep == 1
                            ? 'btn-primary'
                            : 'text-gray-600 bg-gray-200 dark:bg-dark-1'
                        ]"
                      >
                        1
                      </button>
                      <div
                        class="lg:w-32 font-medium text-base lg:mt-3 ml-3 lg:mx-auto"
                      >
                        Tender Details
                      </div>
                    </div>
                    <div
                      class="intro-x lg:text-center flex items-center mt-5 lg:mt-0 lg:block flex-1 z-10"
                    >
                      <button
                        :class="[
                          'w-10 h-10 rounded-full btn',
                          currentStep == 2
                            ? 'btn-primary'
                            : 'text-gray-600 bg-gray-200 dark:bg-dark-1'
                        ]"
                      >
                        2
                      </button>
                      <div
                        class="lg:w-32 text-base lg:mt-3 ml-3 lg:mx-auto text-gray-700 dark:text-gray-600"
                      >
                        Expense Details
                      </div>
                    </div>
                    <div
                      class="intro-x lg:text-center flex items-center mt-5 lg:mt-0 lg:block flex-1 z-10"
                    >
                      <button
                        :class="[
                          'w-10 h-10 rounded-full btn',
                          currentStep == 3
                            ? 'btn-primary'
                            : 'text-gray-600 bg-gray-200 dark:bg-dark-1'
                        ]"
                      >
                        3
                      </button>
                      <div
                        class="lg:w-32 text-base lg:mt-3 ml-3 lg:mx-auto text-gray-700 dark:text-gray-600"
                      >
                        Lot Details
                      </div>
                    </div>
                    <div
                      class="wizard__line hidden lg:block w-2/3 bg-gray-200 dark:bg-dark-1 absolute mt-5"
                    ></div>
                  </div>
                  <div
                    class="w-full lg:w-2/3 m-auto mt-5"
                    v-if="currentStep == 1"
                  >
                    <div>
                      <div class="grid grid-cols-12 gap-4 gap-y-4 mt-2">

                        <div class="intro-y col-span-12 sm:col-span-6">
                          <label for="input-wizard-1" class="form-label mb-0"
                            >Tender Name</label
                          >
                          <input
                            id="input-wizard-1"
                            v-model="formData.tender_name"
                            type="text"
                            name="tender_name"
                            :class="{
                              'border-theme-6': validate.tender_name.$error
                            }"
                            class="form-control"
                          />
                          <template v-if="validate.tender_name.$error">
                            <div
                              v-for="(error, index) in validate.tender_name
                                .$errors"
                              :key="index"
                              class="text-theme-6 mt-2"
                            >
                              {{ error.$message }}
                            </div>
                          </template>
                        </div>
                        <div class="intro-y col-span-12 sm:col-span-6">
                          <label for="input-wizard-6" class="form-label mb-0"
                            >Declare Date</label
                          >
                          <Litepicker
                            v-model="formData.declare_date"
                            :options="{
                              autoApply: false,
                              showWeekNumbers: true,
                              format: 'YYYY-MM-DD',
                              dropdowns: {
                                minYear: 1990,
                                maxYear: 2025,
                                months: true,
                                years: true
                              },
                              minDate: new Date(
                                new Date().getTime() - 24 * 60 * 60 * 1000
                              )
                            }"
                            class="form-control block mx-auto"
                            :class="{
                              'border-theme-6': validate.declare_date.$error
                            }"
                          />
                          <template v-if="validate.declare_date.$error">
                            <div
                              v-for="(error, index) in validate.declare_date
                                .$errors"
                              :key="index"
                              class="text-theme-6 mt-2"
                            >
                              {{ error.$message }}
                            </div>
                          </template>
                        </div>

                        <div class="intro-y col-span-12 sm:col-span-6">
                          <label for="input-wizard-3" class="form-label mb-0"
                            >Tender Date</label
                          >
                          <Litepicker
                            v-model="formData.tender_date"
                            :options="{
                              autoApply: false,
                              format: 'YYYY-MM-DD',
                              showWeekNumbers: true,
                              dropdowns: {
                                minYear: 1990,
                                maxYear: 2025,
                                months: true,
                                years: true
                              },
                              maxDate: new Date()
                            }"
                            :class="{
                              'border-theme-6': validate.tender_date.$error
                            }"
                            class="form-control block mx-auto"
                          />
                          <template v-if="validate.tender_date.$error">
                            <div
                              v-for="(error, index) in validate.tender_date
                                .$errors"
                              :key="index"
                              class="text-theme-6 mt-2"
                            >
                              {{ error.$message }}
                            </div>
                          </template>
                        </div>
                        <div class="intro-y col-span-12 sm:col-span-6">
                          <label for="input-wizard-2" class="form-label mb-0"
                            >Total Lots</label
                          >
                          <input
                            id="input-wizard-2"
                            disabled
                            type="text"
                            v-model="formData.total_lots"
                            class="form-control"
                            :class="{
                              'border-theme-6': validate.total_lots.$error
                            }"
                          />
                          <template v-if="validate.total_lots.$error">
                            <div
                              v-for="(error, index) in validate.total_lots
                                .$errors"
                              :key="index"
                              class="text-theme-6 mt-2"
                            >
                              {{ error.$message }}
                            </div>
                          </template>
                        </div>

                        <div class="intro-y col-span-12 sm:col-span-6">
                          <label for="input-wizard-5" class="form-label mb-0"
                            >Tender Place</label
                          >
                          <TomSelect v-model="formData.tender_place" :class="{
                            'border-theme-6': validate.tender_place.$error
                          }" :options="{
            placeholder: ''
          }">
                            <option value="0">Select Place</option>
                            <option
                              v-for="(location, index) in pageData.locationList"
                              :key="index"
                              :value="location.id"
                            >
                              {{ location.location_name }}
                            </option>
                          </TomSelect>
                          <template v-if="validate.tender_place.$error">
                            <div
                              v-for="(error, index) in validate.tender_place
                                .$errors"
                              :key="index"
                              class="text-theme-6 mt-2"
                            >
                              {{ error.$message }}
                            </div>
                          </template>
                        </div>
                        <div class="intro-y col-span-12 sm:col-span-6">
                          <label for="input-wizard-4" class="form-label mb-0"
                            >Total Weight</label
                          >
                          <input
                            id="input-wizard-4"
                            disabled
                            type="text"
                            v-model="formData.total_weight"
                            class="form-control"
                            :class="{
                              'border-theme-6': validate.total_weight.$error
                            }"
                          />
                          <template v-if="validate.total_weight.$error">
                            <div
                              v-for="(error, index) in validate.total_weight
                                .$errors"
                              :key="index"
                              class="text-theme-6 mt-2"
                            >
                              {{ error.$message }}
                            </div>
                          </template>
                        </div>



                        <div class="intro-y col-span-12 sm:col-span-6">
                          <label for="input-wizard-6" class="form-label mb-0"
                            >Company Name</label
                          >
                          <TomSelect v-model="formData.company_name" :class="{
                            'border-theme-6': validate.company_name.$error
                          }" :options="{
            placeholder: ''
          }">
                            <option value="0">Select Company</option>
                            <option
                              v-for="(company, index) in pageData.companyList"
                              :key="index"
                              :value="company.id"
                              >{{ company.firm_name }}
                            </option>
                          </TomSelect>
                          <template v-if="validate.company_name.$error">
                            <div
                              v-for="(error, index) in validate.company_name
                                .$errors"
                              :key="index"
                              class="text-theme-6 mt-2"
                            >
                              {{ error.$message }}
                            </div>
                          </template>
                        </div>
                        <div class="intro-y col-span-12 sm:col-span-6">
                          <label for="input-wizard-6" class="form-label mb-0"
                            >Total Amount</label
                          >
                          <input
                            id="input-wizard-5"
                            disabled
                            type="text"
                            v-model="formData.total_amount"
                            class="form-control"
                          />
                        </div>

                        <div class="intro-y col-span-12 sm:col-span-6">
                          <label for="input-wizard-6" class="form-label mb-0"
                            >Partner</label
                          >
                          <TomSelect v-model="formData.partner_id" :class="{
                            'border-theme-6': validate.partner_id.$error
                          }" :options="{
            placeholder: ''
          }">
                            <option value="0">Select Partner Company</option>
                            <option
                              v-for="(partner, index) in pageData.partnerList"
                              :key="index"
                              :value="partner.id"
                              >{{ partner.firm_name }}
                            </option>
                          </TomSelect>
                          <template v-if="validate.partner_id.$error">
                            <div
                              v-for="(error, index) in validate.partner_id
                                .$errors"
                              :key="index"
                              class="text-theme-6 mt-2"
                            >
                              {{ error.$message }}
                            </div>
                          </template>
                        </div>
                        <div class="intro-y col-span-12 sm:col-span-6">
                          <label for="input-wizard-6" class="form-label mb-0"
                            >Total Expense</label
                          >
                          <input
                            id="input-wizard-5"
                            disabled
                            type="text"
                            v-model="formData.total_expense"
                            class="form-control"
                            :class="{
                              'border-theme-6': validate.total_expense.$error
                            }"
                          />
                          <template v-if="validate.total_expense.$error">
                            <div
                              v-for="(error, index) in validate.total_expense
                                .$errors"
                              :key="index"
                              class="text-theme-6 mt-2"
                            >
                              {{ error.$message }}
                            </div>
                          </template>
                        </div>

                        <div class="intro-y col-span-12 sm:col-span-6">
                          <label for="input-wizard-6" class="form-label mb-0"
                            >Partnership (%)</label
                          >
                          <input
                            id="input-wizard-5"
                            type="text"
                            v-model="formData.partnership"
                            @keypress="$h.preventInvalidInput"
                            class="form-control"
                            :class="{
                              'border-theme-6': validate.partnership.$error
                            }"
                          />
                          <template v-if="validate.partnership.$error">
                            <div
                              v-for="(error, index) in validate.partnership
                                .$errors"
                              :key="index"
                              class="text-theme-6 mt-2"
                            >
                              {{ error.$message }}
                            </div>
                          </template>
                        </div>
                        <div class="intro-y col-span-12 sm:col-span-6">
                          <label for="input-wizard-6" class="form-label mb-0"
                            >Average Per/ct</label
                          >
                          <input
                            id="input-wizard-5"
                            disabled
                            type="text"
                            v-model="formData.avg"
                            class="form-control"
                          />
                        </div>
                      </div>

                    </div>
                  </div>
                  <div
                    class="w-full lg:w-2/3 m-auto mt-5"
                    v-if="currentStep == 2"
                  >
                    <div class="">
                      <div class="flex">
                        <p class="font-bold text-md mt-2">Upload Excel</p>
                        <button
                          class="btn btn-primary btn-sm ml-5 mb-4"
                          @click="clearDropzone('excel', 0)"
                        >
                          <UploadIcon class="h-4 w-4 mr-2" /> Upload
                        </button>
                        <a
                          class="ml-2 mt-2"
                          target="_blank"
                          :href="baseURL + formData.expense_file"
                          >Link</a
                        >
                      </div>
                      <!-- BEGIN: Form Layout -->
                      <p class="font-bold text-lg pb-2">Expense Details</p>
                      <div class=" w-full">
                        <table class="border-2 pt-5 table-auto w-full">
                          <thead>
                            <tr class="border">
                              <th style="width:5%">SR.NO</th>
                              <th style="width:45%">Expense Name</th>
                              <th style="width:40%">Amount</th>
                              <th style="width:10%" class="text-center">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody class="border-2">
                            <tr
                              class=""
                              v-for="(item, index) in formData.expense_details"
                              :key="index"
                            >
                              <td
                                class="whitespace-nowrap border-b text-center"
                              >
                                {{ index + 1 }}
                              </td>
                              <td class="whitespace-nowrap w-24">
                                <TomSelect v-model="item.expense_name" :options="{
                placeholder: ''
              }">
                                <option value="0">Select Expense Name</option>
                                  <option
                                    v-for="(expense,
                                    index) in pageData.expenseList"
                                    :key="index"
                                    :value="expense.id"
                                    >{{ expense.expense_name }}
                                  </option>
                              </TomSelect>

                              </td>
                              <td
                                class="whitespace-nowrap w-24"
                                style="padding: 2px;"
                              >
                                <input
                                  type="text"
                                  v-model="item.amount"
                                  class="form-control rounded-none w-full"
                                  placeholder="Amount"
                                  aria-describedby="input-group-3"
                                  @keypress="$h.preventInvalidInput"
                                />
                              </td>
                              <td
                                class="whitespace-nowrap text-center"
                                v-if="index != 0"
                              >
                                <button
                                  class="btn btn-danger btn-sm p-1"
                                  @click="deleteRow(index)"
                                >
                                  <Trash2Icon class="h-4 w-4" />
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div class="text-right mt-4">
                        <button
                          class="btn btn-secondary btn-sm"
                          @click="addRow"
                        >
                          <PlusIcon class="h-4 w-4 mr-2" /> Add
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-12 gap-6 mt-5"
                    v-if="currentStep == 3"
                  >
                    <div class="intro-y col-span-12 p-5">
                      <!-- BEGIN: Form Layout -->
                      <p class="font-bold text-lg pb-2">Lot Details</p>
                      <div class="w-full table-container overflow-x-auto">
                        <table class="border-2 pt-5 table-auto w-full">
                          <thead class="">
                            <tr class="border">
                              <th class="py-2">SR.NO</th>
                              <th class="whitespace-nowrap">
                                Lot No
                              </th>
                              <th>Weight</th>
                              <th>Result</th>
                              <th>Color</th>
                              <th>Purity</th>
                              <th>Blue Uv</th>
                              <th>Brown</th>
                              <th>Comment</th>
                              <th>Flow</th>
                              <th>Tension</th>
                              <th>Eyeclc-R</th>
                              <th>Eyeclc-F</th>
                              <th>Center</th>
                              <!-- <th>Price/ct</th>
                              <th class="whitespace-nowrap">Total Price</th> -->
                              <th>Video</th>
                              <th>Cap</th>
                              <th>Image</th>
                              <th class="text-center">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody class="border-2">
                            <tr
                              class=""
                              v-for="(item, index) in formData.lot_details"
                              :key="index"
                            >
                              <td class="text-center border-2">
                                {{ index + 1 }}
                              </td>
                              <td class="whitespace-nowrap w-24">
                                <input
                                  type="text"
                                  v-model="item.lot_no"
                                  class="form-control rounded-none w-20 py-1"
                                  placeholder=""
                                  aria-describedby="input-group-3"
                                />
                              </td>
                              <td class="whitespace-nowrap w-24">
                                <input
                                  type="text"
                                  v-model="item.weight"
                                  class="form-control rounded-none w-20 py-1"
                                  placeholder=""
                                  @input="TotalPrice(index)"
                                  aria-describedby="input-group-3"
                                  @keypress="$h.preventInvalidInput"
                                />
                              </td>
                              <td class="whitespace-nowrap w-24">
                                <select
                                  v-model="item.result"
                                  class="w-20 form-select py-1"
                                >
                                <option value="no_result" label="???">???</option>
                                  <option
                                    v-for="(result, index) in pageData.colorList"
                                    :key="index"
                                    :value="result.id"
                                    >{{ result.color_name }}
                                  </option>
                                </select>
                              </td>
                              <td class="whitespace-nowrap w-24">
                                <select
                                  v-model="item.color"
                                  class="w-20 form-select py-1"
                                >
                                <option value="no_result" label="??">??</option>
                                  <option
                                    v-for="(color, index) in pageData.colorList"
                                    :key="index"
                                    :value="color.id"
                                    >{{ color.color_name }}
                                  </option>
                                </select>
                              </td>
                              <td class="whitespace-nowrap w-24">
                                <select
                                  v-model="item.purity"
                                  class="w-20 form-select py-1"
                                >
                                <option value="no_result" label="??">??</option>
                                  <option
                                    v-for="(purity, index) in pageData.purityList"
                                    :key="index"
                                    :value="purity.id"
                                    >{{ purity.purity_name }}
                                  </option>
                                </select>
                              </td>
                              <td class="whitespace-nowrap w-24">
                                <select
                                  v-model="item.blue_uv"
                                  class="w-20 form-select py-1"
                                >
                                  <option
                                    v-for="(blueUv, index) in pageData.blueUvList"
                                    :key="index"
                                    :value="blueUv"
                                    >{{ blueUv }}
                                  </option>
                                </select>
                              </td>
                              <td class="whitespace-nowrap w-24">
                                <select
                                  v-model="item.brown"
                                  class="w-20 form-select py-1"
                                >
                                  <option
                                    v-for="(brown, index) in pageData.brownList"
                                    :key="index"
                                    :value="brown.id"
                                    >{{ brown.brown_name }}
                                  </option>
                                </select>
                              </td>
                              <td class="whitespace-nowrap w-24">
                                <select
                                  v-model="item.comment"
                                  class="w-20 form-select py-1"
                                >
                                  <option
                                    v-for="(comment, index) in pageData.commentList"
                                    :key="index"
                                    :value="comment.id"
                                    >{{ comment.comment_name }}
                                  </option>
                                </select>
                              </td>
                              <td class="whitespace-nowrap w-24">
                                <select
                                  v-model="item.flow"
                                  class="w-20 form-select py-1"
                                >
                                  <option
                                    v-for="(flow, index) in pageData.flowList"
                                    :key="index"
                                    :value="flow.id"
                                    >{{ flow.fluerocent_name }}
                                  </option>
                                </select>
                              </td>
                              <td class="whitespace-nowrap w-24">
                                <select
                                  v-model="item.tension"
                                  class="w-20 form-select py-1"
                                >
                                  <option
                                    v-for="(tension, index) in pageData.tensionList"
                                    :key="index"
                                    :value="tension.id"
                                    >{{ tension.tension_name }}
                                  </option>
                                </select>
                              </td>
                              <td class="whitespace-nowrap w-24">
                                <select
                                  v-model="item.eyeclc_r"
                                  class="w-20 form-select py-1"
                                >
                                <!-- <option value="no_result" label="??">??</option> -->
                                  <option
                                    v-for="(eyeclc_r, index) in pageData.colorList"
                                    :key="index"
                                    :value="eyeclc_r.id"
                                    >{{ eyeclc_r.color_name }}
                                  </option>
                                </select>
                              </td>
                              <td class="whitespace-nowrap w-24">
                                <select
                                  v-model="item.eyeclc_f"
                                  class="w-20 form-select py-1"
                                >
                                <!-- <option value="no_result" label="??">??</option> -->
                                  <option
                                    v-for="(eyeclc_f, index) in pageData.colorList"
                                    :key="index"
                                    :value="eyeclc_f.id"
                                    >{{ eyeclc_f.color_name }}
                                  </option>
                                </select>
                              </td>
                              <td class="whitespace-nowrap w-24">
                                <select
                                  v-model="item.center"
                                  class="w-20 form-select py-1"
                                >
                                  <option
                                    v-for="(center, index) in pageData.purityList"
                                    :key="index"
                                    :value="center.id"
                                    >{{ center.purity_name }}
                                  </option>
                                </select>
                              </td>
                              <!-- <td class="whitespace-nowrap w-24">
                                <input
                                  type="text"
                                  v-model="item.price_ct"
                                  @input="TotalPrice(index)"
                                  class="form-control rounded-none w-20 py-1"
                                  placeholder=""
                                  aria-describedby="input-group-3"
                                  @keypress="$h.preventInvalidInput"
                                />
                              </td>
                              <td class="whitespace-nowrap w-24">
                                <input
                                  type="text"
                                  v-model="item.total_price"
                                  disabled
                                  class="form-control rounded-none w-20 py-1"
                                  placeholder=""
                                  aria-describedby="input-group-3"
                                  @keypress="$h.preventInvalidInput"
                                />
                              </td> -->
                              <td class="pl-8">
                                <div class="w-16">
                                <button
                                  class="btn btn-outline-primary btn-xs py-1 px-1"
                                  @click="clearDropzone('video', index)"
                                >
                                  <UploadIcon class="h-3 w-3" />
                                </button>
                                <a
                                  v-if="formData.lot_details[index].video != ''"
                                  class="ml-2 btn btn-outline-primary btn-xs py-1 px-1"
                                  target="_blank"
                                  :href="
                                    baseURL + formData.lot_details[index].video
                                  "
                                  ><ImageIcon class="h-3 w-3"
                                /></a>
                              </div>
                              </td>
                              <td class="pl-8">
                                <div class="w-16">
                                <button
                                  class="btn btn-outline-primary btn-xs py-1 px-1"
                                  @click="clearDropzone('cap', index)"
                                >
                                  <UploadIcon class="h-3 w-3" />
                                </button>
                                <a
                                  v-if="formData.lot_details[index].cap != ''"
                                  class="ml-2 btn btn-outline-primary btn-xs py-1 px-1"
                                  target="_blank"
                                  :href="
                                    baseURL + formData.lot_details[index].cap
                                  "
                                  ><ImageIcon class="h-3 w-3"
                                /></a>
                              </div>
                              </td>
                              <td class="pl-8">
                                <div class="w-16">
                                <button
                                  class="btn btn-outline-primary btn-xs py-1 px-1"
                                  @click="clearDropzone('image', index)"
                                >
                                  <UploadIcon class="h-3 w-3" />
                                </button>
                                <a
                                  v-if="formData.lot_details[index].image != ''"
                                  class="ml-2 btn btn-outline-primary btn-xs py-1 px-1"
                                  target="_blank"
                                  :href="
                                    baseURL + formData.lot_details[index].image
                                  "
                                  ><ImageIcon class="h-3 w-3"
                                /></a>
                              </div>
                              </td>
                              <td
                                class="whitespace-nowrap text-center"
                                style="padding: 2px;"
                                v-if="index != 0"
                              >
                                <button
                                  class="btn btn-danger btn-sm p-1"
                                  @click="deleteLotRow(index)"
                                >
                                  <Trash2Icon class="h-4 w-4" />
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="flex justify-between items-center mt-4">
                        <div class="">
                          <div>
                            <button
                              class="btn btn-secondary btn-sm"
                              @click="addSubRow"
                            >
                              <PlusIcon class="h-4 w-4 mr-2" /> Add
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-8" :class="{'w-full lg:w-2/3 m-auto' : (currentStep == 1 || currentStep == 2) }"
                  >
                    <button
                      class="btn btn-secondary w-24"
                      v-if="currentStep == 1"
                      @click="cancelForm"
                    >
                      Cancel
                    </button>
                    <button
                      v-else
                      class="btn btn-secondary w-24"
                      @click="goToPreviousStep"
                    >
                      Previous
                    </button>
                    <button
                      v-if="currentStep == 3" :disabled="pageData.submit"
                       type="button" @click="submitForm"
                      class="btn btn-primary w-24 ml-2"
                    >
                      Save <LoadingIcon v-if="pageData.submit" icon="oval" color="white" class="w-4 h-4 ml-2" />
                    </button>
                    <button
                      v-else
                      class="btn btn-primary w-24 ml-2"
                      @click="goToNextStep"
                    >
                      Next
                    </button>
                  </div>
                </div>
                <!-- END: Wizard Layout -->
              </div>
              <!-- END: Data List -->
            </div>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
    <div id="upload-image-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <a data-dismiss="modal" href="javascript:;">
            <XIcon class="w-8 h-8 text-gray-500" />
          </a>
          <div class="modal-body p-10">
            <!-- <div id="vertical-form"> -->
            <div class="flex flex-col justify-between mt-5">
              <!-- BEGIN: Data List -->
              <div class="intro-y overflow-auto">
                <div class="flex items-center">
                  <h2 class="intro-y text-lg font-medium mr-5">Upload Image</h2>
                </div>
              </div>
              <div class="grid grid-cols-12 gap-4">
                <div class="intro-y col-span-12">
                  <!-- BEGIN: Dropzone -->
                  <div class="dropzone" id="stock-dropzone"></div>
                  <!-- END: Dropzone -->
                </div>
              </div>
              <!-- END: Data List -->
            </div>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="success-notification-content" class="toastify-content hidden flex">
    <CheckCircleIcon class="text-theme-9" />
    <div class="ml-4 mr-4">
      <div class="font-medium">Details saved successfully!</div>
      <div class="text-gray-600 mt-1"></div>
    </div>
  </div>
  <!-- END: Success Notification Content -->
  <!-- BEGIN: Failed Notification Content -->
  <div id="failed-notification-content" class="toastify-content hidden flex">
    <XCircleIcon class="text-theme-6" />
    <div class="ml-4 mr-4">
      <div class="font-medium">Something went wrong!</div>
      <div class="text-gray-600 mt-1">
        Please check the fileld form.
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  computed,
  ref,
  reactive,
  onMounted,
  inject,
  watch,
  provide,
  toRefs,
  toRef
} from 'vue'
import xlsx from 'xlsx'
import { useStore } from '@/store'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import moment from 'moment'
import { useRouter, useRoute } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'
import { ImageIcon, UploadIcon } from '@zhuowenli/vue-feather-icons'
import { helper as $h } from '@/utils/helper'
import Swal from 'sweetalert2'
import Dropzone from 'dropzone'
import Toastify from 'toastify-js'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default defineComponent({
  setup() {
    const router = useRouter()
    const store = useStore()
    const route = useRoute()
    const tableRef = ref()
    const tabulator = ref()
    const stockDropzone = ref(null)
    const baseURL = ref(BASE_URL)
    const filter = reactive({
      field: 'lot_no',
      type: 'like',
      value: ''
    })
    const currentStep = ref(1)
    const totalSteps = 3
    const pageData = reactive({
      errors: [],
      locationList: [],
      companyList: [],
      partnerList: [],
      colorList: [],
      brownList: [],
      tensionList: [],
      flowList: [],
      tensionList: [],
      purityList: [],
      blueUvList:Array.from({ length: 76 }, (_, i) => (i).toString().padStart(3, '0')),
      modelIndex: 0,
      modelFileType: '',
      submit: false,
      token: localStorage.getItem('token')
    })

    const errors = toRef(pageData, 'errors')
    const swal = inject('$swal')
    const userDetails = computed(() => store.state.user.currentUser)

    const filters = reactive({
      company_id: '',
      // start_date: moment()
      //   .startOf('month')
      //   .format('YYYY-MM-DD'),
      // end_date: moment().format('YYYY-MM-DD'),
      tender_date: '',
      tender_name: ''
    })

    const goToNextStep = () => {
      if (currentStep.value < totalSteps) {
        currentStep.value++
      }
    }

    const goToPreviousStep = () => {
      if (currentStep.value > 1) {
        currentStep.value--
      }
    }
    const screenData = reactive({
      transaction_no: '',
      issuer: '',
      status: '0',
      employee_type: '3',
      employee_id: '',
      process_id: '0',
      employees: [],
      parties: [],
      process_items: [],
      emp_hit: 1
    })
    const formData = reactive({
      id: '',
      tender_name: '',
      total_lots: '',
      tender_date: '',
      total_weight: '',
      tender_place: '',
      total_amount: '',
      company_name: '',
      total_expense: '',
      partner_id: '',
      avg: '',
      partnership: '',
      declare_date: '',
      expense_file: '',
      expense_details: [
        {
          expense_name: '',
          amount: ''
        }
      ],
      lot_details: [
        {
          lot_no: '',
          weight: '',
          result:'no_result',
          color: 'no_result',
          purity:'no_result',
          blue_uv:'',
          brown:'',
          comment:'',
          flow:'',
          tension:'',
          eyeclc_r:'',
          eyeclc_f:'',
          center:'',
          price_ct: '',
          total_price: '',
          video: '',
          cap: '',
          image: ''
        }
      ]
    })

    const rules = {
      tender_name: {
        required
      },
      total_lots: {
        required
      },
      tender_date: {
        required
      },
      total_weight: {
        required
      },
      tender_place: {
        required
      },
      // total_amount: {
      //   required
      // },
      company_name: {
        required
      },
      total_expense: {
        required
      },
      partner_id: {
        required
      },
      // avg: {
      //   required
      // },
      partnership: {
        required
      },
      declare_date: {
        required
      }
    }
    const validate = useVuelidate(rules, toRefs(formData))

    const TotalPrice = index => {
      const item = formData.lot_details[index]
      item.total_price = $h.myFloat(item.weight* item.price_ct)
    }

    const TotalAvg = computed(() => {
      formData.avg =
        $h.myFloat(formData.total_amount / formData.total_weight)
    })

    watch(TotalAvg, (newValue, oldValue) => {
      formData.avg = newValue
    })

    const TotalLotNo = computed(() => {
      return formData.lot_details.length
    })

    watch(TotalLotNo, (newValue, oldValue) => {
      formData.total_lots = newValue
    })

    const TotalExpense = computed(() => {
      let total = 0
      for (const item of formData.expense_details) {
        total += parseFloat(item.amount) || 0
      }
      return total
    })

    watch(TotalExpense, (newValue, oldValue) => {
      formData.total_expense = newValue
    })

    const TotalAmount = computed(() => {
      let total = 0
      for (const item of formData.lot_details) {
        total += parseFloat(item.total_price) || 0
      }
      return total
    })

    watch(TotalAmount, (newValue, oldValue) => {
      formData.total_amount = newValue
    })

    const TotalWeight = computed(() => {
      let total = 0
      for (const item of formData.lot_details) {
        total += parseFloat(item.weight) || 0
      }
      return total
    })

    watch(TotalWeight, (newValue, oldValue) => {
      formData.total_weight = newValue
    })

    function get_company_list() {
      axios({
        url: 'api/master/tender_company_list',
        method: 'post',
        baseURL: BASE_URL,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            Accept: 'application/json'
          }
        }
      }).then(res => {
        pageData.companyList = res.data.items
      })
    }

    function get_location_list() {
      axios({
        url: 'api/master/dp_location',
        method: 'post',
        baseURL: BASE_URL,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            Accept: 'application/json'
          }
        }
      }).then(res => {
        pageData.locationList = res.data.items
      })
    }

    function get_partner_list() {
      axios({
        url: 'api/master/partner_company_list',
        method: 'post',
        baseURL: BASE_URL,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            Accept: 'application/json'
          }
        }
      }).then(res => {
        pageData.partnerList = res.data.items
      })
    }

    function get_parameters() {
      axios({
        url: 'api/tender/params',
        method: 'post',
        baseURL: BASE_URL,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            Accept: 'application/json'
          }
        }
      }).then(res => {
        pageData.colorList = res.data.color
        pageData.brownList = res.data.brown
        pageData.tensionList = res.data.tension
        pageData.flowList = res.data.fluerocent
        pageData.purityList = res.data.purity
        pageData.commentList = res.data.comment
      })
    }


    function get_expense_list() {
      axios({
        url: 'api/master/dp_expense',
        method: 'post',
        baseURL: BASE_URL,
        data: { type: window.btoa(1) },
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            Accept: 'application/json'
          }
        }
      }).then(res => {
        pageData.expenseList = res.data.items
      })
    }

    const addRow = () => {
      formData.expense_details.push({
        // expense_name: String(pageData.expenseList[0].id),
        expense_name: '',
        amount: ''
      })
    }

    const deleteRow = index => {
      console.log(index,'index11');
      swal({
        title: 'Are you sure?',
        text: 'You are about to Remove' + ' Record!',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirm',
        showLoaderOnConfirm: true,
        cancelButtonText: 'Cancel',
        preConfirm: () => {
          return true
        },

        allowOutsideClick: () => !Swal.isLoading()
      }).then(result => {
        if (result.isConfirmed) {
          console.log(index,'index');
          formData.expense_details.splice(index, 1)
        }
      })
    }
    const addSubRow = () => {
      formData.lot_details.push({
        // expense_name: String(pageData.expenseList[0].id),
        lot_no: '',
        weight: '',
        result:'no_result',
          color: 'no_result',
          purity:'no_result',
          blue_uv:'',
          brown:'',
          comment:'',
          flow:'',
          tension:'',
          eyeclc_r:'',
          eyeclc_f:'',
          center:'',
        price_ct: '',
        total_price: '',
        video: '',
        cap: '',
        image: ''
      })
    }

    const deleteLotRow = index => {
      swal({
        title: 'Are you sure?',
        text: 'You are about to Remove' + ' Record!',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirm',
        showLoaderOnConfirm: true,
        cancelButtonText: 'Cancel',
        preConfirm: () => {
          return true
        },

        allowOutsideClick: () => !Swal.isLoading()
      }).then(result => {
        if (result.isConfirmed) {
          formData.lot_details.splice(index, 1)
        }
      })
    }
    const cancelForm = () => {
      cash('#new-rough-tender-modal').modal('hide')
    }

    const open_tender_modal = () => {
      resetForm()
      cash('#new-rough-tender-modal').modal('show')
    }

    const create_tender_winning = () => {
      router.push('/tender/winning/create')
    }
    const resetForm = () => {
      ;(formData.tender_name = ''),
        (formData.tender_date = ''),
        (formData.total_weight = ''),
        (formData.tender_place = ''),
        (formData.total_amount = ''),
        (formData.company_name = ''),
        (formData.total_expense = ''),
        (formData.partner_id = ''),
        (formData.avg = ''),
        (formData.partnership = ''),
        (formData.declare_date = ''),
        (formData.expense_file = ''),
        (formData.expense_details = [
          {
            expense_name: '',
            amount: ''
          }
        ]),
        (formData.lot_details = [
          {
            lot_no: '',
            weight: '',
            result:'no_result',
          color: 'no_result',
          purity:'no_result',
          blue_uv:'',
          brown:'',
          comment:'',
          flow:'',
          tension:'',
          eyeclc_r:'',
          eyeclc_f:'',
          center:'',
            price_ct: '',
            total_price: '',
            video: '',
            cap: '',
            image: ''
          }
        ]),
        (currentStep.value = 1)
    }
    function submitForm() {
      validate.value.$touch()
      if (validate.value.$invalid) {
        Toastify({
          node: cash('#failed-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
      } else {
        pageData.submit = true
        const params = {
          tender_name: formData.tender_name,
          total_lots: formData.total_lots,
          tender_date: formData.tender_date,
          total_weight: formData.total_weight,
          tender_place: formData.tender_place,
          total_amount: formData.total_amount,
          company_name: formData.company_name,
          total_expense: formData.total_expense,
          partner_id: formData.partner_id,
          avg: formData.avg,
          partnership: formData.partnership,
          declare_date: formData.declare_date,
          expense_file: formData.expense_file,
          expense_details: formData.expense_details,
          lot_details: formData.lot_details,
          id: btoa(formData.id)
        }
        axios({
          url: 'api/tender/create',
          method: 'post',
          baseURL: BASE_URL,
          data: params,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { Accept: 'application/json' } }
        })
          .then(() => {
            pageData.submit = false
            cash('#new-rough-tender-modal').modal('hide')
            Toastify({
              node: cash('#success-notification-content')
                .clone()
                .removeClass('hidden')[0],
              duration: 3000,
              newWindow: true,
              close: true,
              gravity: 'top',
              position: 'right',
              stopOnFocus: true
            }).showToast()
            validate.value.$reset()
            resetForm()
            initTabulator()
          })
          .catch(err => {
            pageData.submit = false
            // errors.value = err.response.data.errors
            setTimeout(() => {
              pageData.errors = []
            }, 5000)
          })
      }
    }

    const cancel_tender = id => {
      const params = { id: btoa(id) }

      swal({
        title: 'Are you sure?',
        text: 'Do you want to change the status?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, cancel it!',
        cancelButtonText: 'No, keep it'
      }).then(result => {
        if (result.value) {
          axios({
            url: 'api/tender/cancel',
            method: 'post',
            baseURL: BASE_URL,
            data: params,
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token')
            },
            config: { headers: { Accept: 'application/json' } }
          })
            .then(res => {
              swal({
                text: res.data.msg,
                icon: 'success',
                buttonsStyling: false,
                confirmButtonText: 'Ok, got it!',
                heightAuto: false,
                customClass: {
                  confirmButton: 'btn btn-sm btn-primary btm-sm py-2'
                }
              })
              initTabulator()
            })
            .catch(error => {
              // Handle error if needed
            })
        }
      })
    }

    const get_tender_data = id => {
      const params = {
        id: btoa(id)
      }

      let promise = axios({
        url: 'api/tender/edit',
        method: 'POST',
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { Accept: 'application/json' } }
      })
      return promise
        .then(result => result.data)
        .then(data => {
          formData.tender_name = data.item.tender_name
          formData.tender_date = data.item.tender_date
          formData.total_lots = data.item.total_lots
          formData.total_weight = data.item.total_weight
          formData.total_amount = data.item.total_amount
          formData.total_expense = data.item.total_expense
          formData.company_name = data.item.company_id
          formData.tender_place = data.item.tender_place
          formData.partner_id = data.item.partner_id
          formData.partnership = data.item.partnership
          formData.expense_details = data.item.expense_details
          formData.lot_details = data.item.lot_details
          formData.id = data.item.id
          cash('#new-rough-tender-modal').modal('show')
        })
        .catch(_error => {})
    }

    const initTabulator = () => {
      let params = {
        company_id: window.btoa(filters.company_id),
        tender_date: window.btoa(filters.tender_date),
        tender_name: window.btoa(filters.tender_name)
      }

      let _this = this
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + 'api/tender/index',
        ajaxConfig: {
          method: 'GET', //set request type to Position
          headers: {
            'Content-type': 'application/json; charset=utf-8', //set specific content type
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        },
        ajaxParams: params,
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: false,
        tooltipsHeader: true,
        headerSort: false,
        placeholder: 'No matching records found',
        columns: [
          // For HTML table
          {
            title: 'Tender Name',
            minWidth: 100,
            responsive: 0,
            field: 'tender_name',
            vertAlign: 'middle',
            print: false,
            download: true,

            formatter(cell) {
              const a = cash(`<div>
                            ${cell.getData().tender_name}
                            </div>`)
              cash(a).on('click', function() {
                view_details(cell.getData().id)
              })
              return a[0]
            }
          },
          {
            title: 'Company Name',
            minWidth: 100,
            field: 'company_id',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return cell.getData().tender_company.firm_name
            }
          },
          {
            title: 'Tender Date',
            minWidth: 100,
            field: 'tender_time',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Tender Place',
            minWidth: 100,
            field: 'location',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return cell.getData().location.location_name
            }
          },
          {
            title: 'Total Lot',
            minWidth: 100,
            field: 'total_lots',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Total Weight',
            minWidth: 100,
            field: 'total_weight',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Total Amount',
            minWidth: 100,
            field: 'total_amount',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Partner',
            minWidth: 100,
            field: 'partner',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              return cell.getData().partner?.firm_name
            }
          },
          {
            title: 'Declare Date',
            minWidth: 100,
            field: 'declare_time',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: true
          },
          {
            title: 'Status',
            minWidth: 100,
            field: 'active_status',
            vertAlign: 'middle',
            print: false,
            download: false,

            formatter(cell) {
              return `<div class="flex lg:justify-center ${
                cell.getData().statusText == 'Cancelled'
                  ? 'text-theme-6': cell.getData().statusText == 'Won'?
                   'text-theme-1' : cell.getData().statusText == 'Active'? 'text-theme-9':'text-theme-6'
              }">
                             ${cell.getData().statusText}
                          </div>
                          `
            }
          },
          {
            title: 'Action',
            hozAlign: 'center',
            field: 'status',
            minWidth: 100,
            vertAlign: 'middle',
            print: false,
            responsive: 0,
            download: false,
            formatter(cell) {
              const dropdown = document.createElement('div')
              dropdown.classList.add('dropdown')

              const button = document.createElement('button')
              button.classList.add(
                'btn',
                'btn-secondary',
                'btn-sm',
                'dropdown-toggle'
              )
              button.setAttribute('id', 'dropdownMenuButton')
              button.setAttribute('type', 'button')
              button.setAttribute('data-toggle', 'dropdown')
              button.setAttribute('aria-haspopup', 'true')
              button.setAttribute('aria-expanded', 'false')
              button.innerText = 'Action'

              const menu_icon = document.createElement('i')
              menu_icon.classList.add('w-4', 'h-4', 'ml-2')
              menu_icon.setAttribute('data-feather', 'chevron-down')
              button.appendChild(menu_icon)

              const menu = document.createElement('div')
              menu.setAttribute('aria-labelledby', 'dropdownMenuButton')
              menu.classList.add('dropdown-menu')

              const sub_menue = document.createElement('div')
              sub_menue.classList.add(
                'dropdown-menu__content',
                'box',
                'bg-theme-26',
                'dark:bg-dark-6',
                'text-white'
              )

              //View
              const main_view_details = document.createElement('div')
              main_view_details.classList.add(
                'p-1',
                'border-t',
                'border-theme-27',
                'dark:border-dark-3'
              )
              const view_details = document.createElement('div')
              view_details.classList.add(
                'flex',
                'items-center',
                'block',
                'p-2',
                'transition',
                'duration-300',
                'ease-in-out',
                'rounded-md',
                'cursor-pointer',
                'hover:bg-theme-1',
                'dark:hover:bg-dark-3'
              )
              view_details.setAttribute('data-dismiss', 'dropdown')
              view_details.innerText = 'View Details'

              view_details.addEventListener('click', function handleClick(
                event
              ) {
                router.push('/tender/view/' + window.btoa(cell.getData().id))
              })
              main_view_details.appendChild(view_details)
              sub_menue.appendChild(main_view_details)

              // Edit
              const main_edit = document.createElement('div')
              main_edit.classList.add(
                'p-1',
                'border-t',
                'border-theme-27',
                'dark:border-dark-3'
              )
              const edit = document.createElement('div')
              edit.setAttribute('data-dismiss', 'dropdown')
              edit.classList.add(
                'flex',
                'items-center',
                'block',
                'p-2',
                'transition',
                'duration-300',
                'ease-in-out',
                'rounded-md',
                'cursor-pointer',
                'hover:bg-theme-1',
                'dark:hover:bg-dark-3'
              )
              edit.innerText = 'Edit Details'

              edit.addEventListener('click', function handleClick(event) {
                get_tender_data(cell.getData().id)
              })
              main_edit.appendChild(edit)
              sub_menue.appendChild(main_edit)

              //Cancel
              const main_reject_purchase = document.createElement('div')
              main_reject_purchase.classList.add(
                'p-1',
                'border-t',
                'border-theme-27',
                'dark:border-dark-3'
              )
              const reject_purchase = document.createElement('div')
              reject_purchase.setAttribute('data-dismiss', 'dropdown')
              reject_purchase.classList.add(
                'flex',
                'items-center',
                'block',
                'p-2',
                'transition',
                'duration-300',
                'ease-in-out',
                'rounded-md',
                'cursor-pointer',
                'hover:bg-theme-1',
                'dark:hover:bg-dark-3'
              )
              reject_purchase.innerText = 'Cancel Tender'

              reject_purchase.addEventListener('click', function handleClick(
                event
              ) {
                cancel_tender(cell.getData().id)
              })
              main_reject_purchase.appendChild(reject_purchase)
              sub_menue.appendChild(main_reject_purchase)

              menu.appendChild(sub_menue)
              dropdown.appendChild(button)
              dropdown.appendChild(menu)

              // const item2 = document.createElement("a");
              // item2.classList.add("dropdown-item");
              // item2.innerText = "Item 2";
              // menu.appendChild(item2);

              const b = cash(dropdown)
              return b[0]

              const a = cash(
                `<div style='display: flex'>
                                            <div class='dropdown'>
                                             <button class='btn btn-secondary dropdown-toggle' type='button' id='dropdownMenuButton' data-bs-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>Action</button>
                                             <div class='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                                              <div class="dropdown-menu__content box bg-theme-26 dark:bg-dark-6 text-white">` +
                  (cell.getData().status == 0 &&
                  cell.getData().pr_status == null
                    ? `<div class='p-2 border-t border-theme-27 dark:border-dark-3'><button class="flex items-center p-2"><i data-feather="edit" class="w-4 h-4 mr-3"></i> Edit </button></div>`
                    : ``) +
                  (cell.getData().status == 0
                    ? `<div class='p-2 border-t border-theme-27 dark:border-dark-3'><button class="flex items-center p-1"><i data-feather="trash-2" class="w-4 h-4 mr-3"></i> Reject Purchase</button></div>`
                    : '') +
                  (cell.getData().status == 0
                    ? `<div class='p-2 border-t border-theme-27 dark:border-dark-3'><button class="flex items-center p-1"><i data-feather="pie-chart" class="w-4 h-4 mr-3"></i> Reject Pieces</button></div>`
                    : '') +
                  (cell.getData().status == 0
                    ? `<div class='p-2 border-t border-theme-27 dark:border-dark-3'><button class="flex items-center p-1"><i data-feather="chevrons-right" class="w-4 h-4 mr-3"></i> Issue</button></div>`
                    : '') +
                  (cell.getData().status == 0
                    ? `<div class='p-2 border-t border-theme-27 dark:border-dark-3'><button class="flex items-center p-1"><i data-feather="plus" class="w-4 h-4 mr-3"></i> New Pieces</button></div>`
                    : '') +
                  `<div class='p-2 border-t border-theme-27 dark:border-dark-3'><button class="flex items-center p-1"> View Details</button></div>` +
                  `</div>
                                              </div></div></div>`
              )
              cash(a).on('click', function() {
                // get_purchase_data(cell.getData().id)
              })
              return a[0]
            }
          }
          // {
          //   title: 'Print',
          //   minWidth: 100,
          //   width: 100,
          //   field: '',
          //   responsive: 1,
          //   hozAlign: 'center',
          //   vertAlign: 'middle',
          //   headerSort: false,
          //   print: false,
          //   download: false,
          //   formatter(cell) {
          //     const a = cash(`<div class="flex lg:justify-center items-center">
          //                     <button class="text-primary-1 p-1">
          //                       <i data-feather="printer" class="w-4 h-4 mr-1"></i>
          //                     </button>
          //                   </div>`)
          //     cash(a).on('click', function() {
          //       print_details(cell.getData().id)
          //     })
          //     return a[0]
          //   }
          // }
        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }
    function getParties() {
      axios({
        url: 'api/master/tender_company_list',
        method: 'post',
        baseURL: BASE_URL,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            Accept: 'application/json'
          }
        }
      }).then(res => {
        screenData.parties = res.data.items
      })
    }

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    // Filter function
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, filter.type, filter.value)
    }

    //Edit user
    const editUser = id => {
      console.log(id)
    }

    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'data.csv')
    }

    const onExportJson = () => {
      tabulator.value.download('json', 'data.json')
    }

    const onExportXlsx = () => {
      const win = window
      win.XLSX = xlsx
      tabulator.value.download('xlsx', 'buyer_list.xlsx', {
        sheetName: 'Buyers'
      })
    }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }

    const view_details = id => {
      router.push({
        path: '/tender/view/' + window.btoa(id)
      })
    }
    const print_details = id => {
      let url = BASE_URL + 'outward_prints/' + '?trans_id=' + window.btoa(id)
      window.open(url, '_blank')
    }
    const onResetFilter = () => {
      filters.tender_name = ''
      // filters.start_date = moment()
      //   .startOf('month')
      //   .format('YYYY-MM-DD')
      // filters.end_date = moment().format('YYYY-MM-DD')
      filters.tender_date = ''
      filters.company_id = ''
      initTabulator()
    }

    onMounted(() => {
      initTabulator()
      getParties()
      reInitOnResizeWindow()
      get_company_list()
      get_location_list()
      get_parameters()
      get_partner_list()
      get_expense_list()
      onResetFilter()

      formData.total_lots = TotalLotNo.value

      stockDropzone.value = new Dropzone('#stock-dropzone', {
        url: `${BASE_URL}api/tender/upload?file_type=` + pageData.modelFileType,
        thumbnailWidth: 150,
        addRemoveLinks: true,
        maxFiles: 1,
        headers: {
          Authorization: 'Bearer ' + window.localStorage.getItem('token')
        },
        init: function() {
          // this.on("addedfile", (file) => {
          // uploadStockSubmitButton.value = true;
          // uploadStockSubmitRef.value.setAttribute("data-kt-indicator", "on");
          // });

          this.on('error', function(file, errorMessage) {
            this.removeFile(file)
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'No more files please!'
            })
          })

          this.on('success', function(file, response) {
            const packetIndex = pageData.modelIndex
            const modelFileType = pageData.modelFileType
            console.log(response.file_name, 'response')

            if (modelFileType == 'image') {
              formData.lot_details[packetIndex].image = response.file_name
            } else if (modelFileType == 'video') {
              formData.lot_details[packetIndex].video = response.file_name
            } else if (modelFileType == 'cap') {
              formData.lot_details[packetIndex].cap = response.file_name
            } else if (modelFileType == 'excel') {
              formData.expense_file = response.file_name
            }
            cash('#upload-image-modal').modal('hide')

            // purchase.packet_details[packetIndex].url = response.path;

            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'File Uploaded'
            })
            this.removeFile(file)
          })
        }
      })
    })

    const clearDropzone = (type, inx) => {
      pageData.modelFileType = type
      pageData.modelIndex = inx
      cash('#upload-image-modal').modal('show')
    }

    return {
      tableRef,
      filters,
      screenData,
      userDetails,
      filter,
      onFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      initTabulator,
      onPrint,
      editUser,
      onResetFilter,
      open_tender_modal,
      currentStep,
      goToNextStep,
      goToPreviousStep,
      totalSteps,
      formData,
      addRow,
      deleteRow,
      addSubRow,
      deleteLotRow,
      pageData,
      get_company_list,
      get_location_list,
      get_partner_list,
      get_expense_list,
      TotalPrice,
      TotalWeight,
      TotalLotNo,
      TotalAmount,
      TotalAvg,
      clearDropzone,
      stockDropzone,
      submitForm,
      validate,
      cancel_tender,
      get_tender_data,
      resetForm,
      cancelForm,
      baseURL,
      create_tender_winning,
      view_details
    }
  }
})
</script>
<style scoped>
a:link {
  text-decoration: underline;
}
.btn.btn-outline-primary.btn-xs.py-1:hover {
  background-color: transparent;
}
#new-rough-tender-modal {
  padding-left: 0 !important;
}
.modal-body-w {
  height: 100vh !important;
}
.overflow-auto {
  scrollbar-width: thin;
}
.overflow-x-auto {
  scrollbar-width: thin;
}

</style>
