<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Company Create</h2>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-6">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div id="input" class="p-5">
            <div class="preview">
              <div>
                <label for="regular-form-2" class="form-label"
                  >First Name & Last Name</label
                >
                <div class="sm:grid grid-cols-3 gap-2">
                  <div>
                    <div>
                      <input
                        v-model="party.first_name"
                        id="regular-form-1"
                        type="text"
                        class="form-control"
                        placeholder="First Name"
                      />
                    </div>
                    <span class="text-primary-3" v-if="errors.first_name">{{
                      errors.first_name[0]
                    }}</span>
                  </div>
                  <div>
                    <div>
                      <input
                        v-model="party.last_name"
                        id="regular-form-1"
                        type="text"
                        class="form-control"
                        placeholder="Last Name"
                      />
                    </div>
                    <span class="text-primary-3" v-if="errors.last_name">{{
                      errors.last_name[0]
                    }}</span>
                  </div>
                  <div>
                    <div>
                      <input
                        v-model="party.short_name"
                        id="regular-form-1"
                        type="text"
                        class="form-control"
                        placeholder="Short Name"
                      />
                    </div>
                    <span class="text-primary-3" v-if="errors.short_name">{{
                      errors.short_name[0]
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <label class="form-label">Email Id & Mobile No</label>
                <div class="sm:grid grid-cols-2 gap-2">
                  <div>
                    <div class="input-group">
                      <input
                        v-model="party.email"
                        id="regular-form-1"
                        type="email"
                        class="form-control"
                        placeholder="Email"
                      />
                    </div>
                    <span class="text-primary-3" v-if="errors.email">{{
                      errors.email[0]
                    }}</span>
                  </div>
                  <div class="mt-2 sm:mt-0">
                    <div class="input-group">
                      <input
                        type="tel"
                        v-model="party.mobile_no"
                        class="form-control"
                        placeholder="Mobile No"
                        aria-describedby="input-group-3"
                      />
                    </div>
                    <span class="text-primary-3" v-if="errors.mobile_no">{{
                      errors.mobile_no[0]
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <label class="form-label">Firm Name & Location</label>
                <div class="sm:grid grid-cols-2 gap-2">
                  <div>
                    <div class="input-group">
                      <input
                        type="tel"
                        v-model="party.firm_name"
                        class="form-control"
                        placeholder="Firm Name"
                        aria-describedby="input-group-3"
                      />
                    </div>
                    <span class="text-primary-3" v-if="errors.firm_name">{{
                      errors.firm_name[0]
                    }}</span>
                  </div>
                  <div>
                    <div class="input-group">
                      <TomSelect
                        v-model="party.location_id"
                        :options="{
                          placeholder: 'Select your location'
                        }"
                        class="w-full"
                      >
                        <option value="0">Select Location</option>
                        <option
                          v-for="(location, index) in pageData.locationList"
                          :key="index"
                          :value="location.id"
                          >{{ location.location_name }}
                        </option>
                      </TomSelect>
                    </div>
                    <span class="text-primary-3" v-if="errors.location_id">{{
                      errors.location_id[0]
                    }}</span>
                  </div>
                </div>
              </div>

              <div class="text-right mt-5">
                <router-link
                  :to="{ name: 'tender-company-details' }"
                  class="btn btn-outline-secondary w-24 mr-1"
                >
                  Cancel
                </router-link>
                <button
                  type="button"
                  @click="SaveParty"
                  class="btn btn-primary w-24"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Input -->
      </div>
    </div>
  </div>
  <div id="success-notification-content" class="toastify-content hidden flex">
    <CheckCircleIcon class="text-theme-9" />
    <div class="ml-4 mr-4">
      <div class="font-medium">Details saved successfully!</div>
      <div class="text-gray-600 mt-1"></div>
    </div>
  </div>
  <!-- END: Success Notification Content -->
  <!-- BEGIN: Failed Notification Content -->
  <div id="failed-notification-content" class="toastify-content hidden flex">
    <XCircleIcon class="text-theme-6" />
    <div class="ml-4 mr-4">
      <div class="font-medium">Something went wrong!</div>
      <div class="text-gray-600 mt-1">
        Please check the fileld form.
      </div>
    </div>
  </div>
  <!-- END: Failed Notification Content -->
</template>

<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import router from '@/router'
import { useRoute } from 'vue-router'
import axios from 'axios'
import { defineComponent, onMounted, reactive, ref, toRef } from 'vue'
import toastify from 'toastify-js'
export default defineComponent({
  setup() {
    const party = reactive({
      id: '',
      first_name: '',
      last_name: '',
      short_name: '',
      email: '',
      firm_name: '',
      location_id: '',
      mobile_no: ''
    })
    const pageData = reactive({
      errors: [],
      locationList: []
    })
    const errors = toRef(pageData, 'errors')
    const route = useRoute()
    onMounted(() => {
      if (route.params.id) {
        party.id = route.params.id
        getParty()
      }
      get_location_list()
    })
    function SaveParty() {
      if (route.params.id) {
        axios({
          url: 'api/master/tender_company_update',
          method: 'post',
          baseURL: BASE_URL,
          data: party,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { Accept: 'application/json' } }
        }).then(res => {
          toastify({
            node: cash('#success-notification-content')
              .clone()
              .removeClass('hidden')[0],
            duration: 3000,
            newWindow: true,
            close: true,
            gravity: 'top',
            position: 'right',
            stopOnFocus: true
          }).showToast()
          router.push({ name: 'tender-company-details' })
        })
      } else {
        axios({
          url: 'api/master/tender_company_create',
          method: 'post',
          baseURL: BASE_URL,
          data: party,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { Accept: 'application/json' } }
        })
          .then(() => {
            toastify({
              node: cash('#success-notification-content')
                .clone()
                .removeClass('hidden')[0],
              duration: 3000,
              newWindow: true,
              close: true,
              gravity: 'top',
              position: 'right',
              stopOnFocus: true
            }).showToast()
            router.push({ name: 'tender-company-details' })
          })
          .catch(err => {
            errors.value = err.response.data.errors
            setTimeout(() => {
              pageData.errors = []
            }, 5000)
          })
      }
    }

    function get_location_list() {
      axios({
        url: 'api/master/dp_location',
        method: 'post',
        baseURL: BASE_URL,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            Accept: 'application/json'
          }
        }
      }).then(res => {
        pageData.locationList = res.data.items
      })
    }
    function getParty() {
      const params = {
        id: route.params.id
      }
      axios({
        url: 'api/master/tender_company_show',
        method: 'post',
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { Accept: 'application/json' } }
      }).then(res => {
        party.first_name = res.data.item.first_name
        party.last_name = res.data.item.last_name
        party.short_name = res.data.item.short_name
        party.email = res.data.item.email
        party.mobile_no = res.data.item.mobile_no
        party.firm_name = res.data.item.firm_name
        party.location_id = String(res.data.item.location_id)
      })
    }
    return {
      party,
      pageData,
      errors,
      SaveParty
    }
  }
})
</script>
