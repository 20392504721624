<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">
        Tender Details
      </h2>
    </div>
    <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 lg:col-span-5">
                <div>
                  <label
                    for="update-profile-form-6"
                    class="form-label font-bold"
                    >Tender Name :&nbsp;</label
                  ><label for="update-profile-form-6" class="form-label">
                    {{ page_data.tenderDetail.tender_name }}</label
                  >
                </div>
                <div class="mt-2">
                  <label
                    for="update-profile-form-6"
                    class="form-label font-bold"
                    >Company Name :&nbsp;</label
                  ><label for="update-profile-form-6" class="form-label">
                    {{
                      page_data.tenderDetail.tender_company?.firm_name
                    }}</label
                  >
                </div>
                <div class="mt-2">
                  <label
                    for="update-profile-form-6"
                    class="form-label font-bold"
                    >Tender Date :&nbsp;</label
                  ><label for="update-profile-form-6" class="form-label">
                    {{ page_data.tenderDetail.tender_date }}
                  </label>
                </div>
                <div class="mt-2">
                  <label
                    for="update-profile-form-6"
                    class="form-label font-bold"
                    >Declare Date :&nbsp;</label
                  ><label for="update-profile-form-6" class="form-label">
                    {{ page_data.tenderDetail.declare_date }}</label
                  >
                </div>

                <div class="mt-2">
                  <label
                    for="update-profile-form-6"
                    class="form-label font-bold"
                    >Tender Place :&nbsp;</label
                  ><label for="update-profile-form-6" class="form-label">
                    {{ page_data.tenderDetail.location?.location_name }}</label
                  >
                </div>
                <div class="mt-2">
                  <label
                    for="update-profile-form-6"
                    class="form-label font-bold"
                    >Partner :&nbsp;</label
                  ><label for="update-profile-form-6" class="form-label">
                    {{ page_data.tenderDetail.partner?.firm_name }}</label
                  >
                </div>
                <div class="mt-2">
                  <label
                    for="update-profile-form-6"
                    class="form-label font-bold"
                    >Partnership(%) :&nbsp;</label
                  ><label for="update-profile-form-6" class="form-label">
                    {{ page_data.tenderDetail.partnership }}</label
                  >
                </div>

                <div
                  class="mt-2"
                  v-if="page_data.tenderDetail.statusText == 'Won'"
                >
                  <label
                    for="update-profile-form-6"
                    class="form-label font-bold"
                    >Status :&nbsp;</label
                  >
                  <span
                    class="text-xs font-medium me-2 px-2.5 py-0.5 rounded"
                    :class="
                      page_data.tenderDetail.statusText == 'Cancelled'
                        ? 'bg-red-100 text-red-800'
                        : page_data.tenderDetail.statusText == 'Won'
                        ? 'bg-blue-100 text-blue-800'
                        : page_data.tenderDetail.statusText == 'Active'
                        ? 'bg-green-100 text-green-800'
                        : 'bg-red-100 text-red-800'
                    "
                    >{{ page_data.tenderDetail.statusText }}</span
                  >
                </div>
                <div
                  class="mt-2"
                  v-if="page_data.tenderDetail.statusText == 'Won'"
                >
                  <label
                    for="update-profile-form-6"
                    class="form-label font-bold"
                    >Expense File:&nbsp;</label
                  ><label for="update-profile-form-6" class="form-label">
                    <a
                      target="_blank"
                      :href="baseURL + page_data.tenderDetail.expense_file"
                      >Excel</a
                    ></label
                  >
                </div>
                <div
                  class="mt-2"
                  v-if="page_data.tenderDetail.statusText == 'Won'"
                >
                  <label
                    for="update-profile-form-6"
                    class="form-label font-bold"
                    >Finance Company :&nbsp;</label
                  ><label for="update-profile-form-6" class="form-label">
                    {{
                      page_data.tenderDetail?.tender_winning
                        ?.finance_company_detail?.firm_name
                    }}</label
                  >
                </div>
              </div>
              <div class="col-span-12 lg:col-span-5">
                <div class="mt-2">
                  <label
                    for="update-profile-form-6"
                    class="form-label font-bold"
                    >Total Lots :&nbsp;</label
                  ><label for="update-profile-form-6" class="form-label">
                    {{ page_data.tenderDetail.total_lots }}</label
                  >
                </div>
                <div class="mt-2">
                  <label
                    for="update-profile-form-6"
                    class="form-label font-bold"
                    >Total Weight :&nbsp;</label
                  ><label for="update-profile-form-6" class="form-label">
                    {{ page_data.tenderDetail.total_weight }}</label
                  >
                </div>
                <div class="mt-2">
                  <label
                    for="update-profile-form-6"
                    class="form-label font-bold"
                    >Total Amount :&nbsp;</label
                  ><label for="update-profile-form-6" class="form-label">
                    {{
                      page_data.tenderDetail.total_amount
                        ? page_data.tenderDetail.total_amount
                        : 0
                    }}</label
                  >
                </div>
                <div class="mt-2">
                  <label
                    for="update-profile-form-6"
                    class="form-label font-bold"
                    >Total Expense :&nbsp;</label
                  ><label for="update-profile-form-6" class="form-label">
                    <a
                      href="javascript:;"
                      data-toggle="modal"
                      data-target="#expense-detail-modal"
                      >{{ page_data.tenderDetail.total_expense }}</a
                    ></label
                  >
                </div>
                <div
                  class="mt-2"
                  v-if="page_data.tenderDetail.statusText != 'Won'"
                >
                  <label
                    for="update-profile-form-6"
                    class="form-label font-bold"
                    >Expense File:&nbsp;</label
                  ><label for="update-profile-form-6" class="form-label">
                    <a
                      target="_blank"
                      :href="baseURL + page_data.tenderDetail.expense_file"
                      >Excel</a
                    ></label
                  >
                </div>
                <div
                  class="mt-2"
                  v-if="page_data.tenderDetail.statusText != 'Won'"
                >
                  <label
                    for="update-profile-form-6"
                    class="form-label font-bold"
                    >Status :&nbsp;</label
                  >
                  <span
                    class="text-xs font-medium me-2 px-2.5 py-0.5 rounded"
                    :class="
                      page_data.tenderDetail.statusText == 'Cancelled'
                        ? 'bg-red-100 text-red-800'
                        : page_data.tenderDetail.statusText == 'Won'
                        ? 'bg-blue-100 text-blue-800'
                        : page_data.tenderDetail.statusText == 'Active'
                        ? 'bg-green-100 text-green-800'
                        : 'bg-red-100 text-red-800'
                    "
                    >{{ page_data.tenderDetail.statusText }}</span
                  >
                </div>
                <div
                  class="mt-2"
                  v-if="page_data.tenderDetail.statusText == 'Won'"
                >
                  <label
                    for="update-profile-form-6"
                    class="form-label font-bold"
                    >Win. Packets :&nbsp;</label
                  ><label for="update-profile-form-6" class="form-label">
                    {{
                      page_data.tenderDetail?.tender_winning?.lot_items.length
                    }}</label
                  >
                </div>
                <div
                  class="mt-2"
                  v-if="page_data.tenderDetail.statusText == 'Won'"
                >
                  <label
                    for="update-profile-form-6"
                    class="form-label font-bold"
                    >Win. Weight :&nbsp;</label
                  ><label for="update-profile-form-6" class="form-label">
                    {{
                      page_data.tenderDetail?.tender_winning?.total_weight
                    }}</label
                  >
                </div>
                <div
                  class="mt-2"
                  v-if="page_data.tenderDetail.statusText == 'Won'"
                >
                  <label
                    for="update-profile-form-6"
                    class="form-label font-bold"
                    >Win. Per/Ct :&nbsp;</label
                  ><label for="update-profile-form-6" class="form-label">
                    {{
                      page_data.tenderDetail?.tender_winning?.per_ct_price
                    }}</label
                  >
                </div>
                <div
                  class="mt-2"
                  v-if="page_data.tenderDetail.statusText == 'Won'"
                >
                  <label
                    for="update-profile-form-6"
                    class="form-label font-bold"
                    >Total Price :&nbsp;</label
                  ><label for="update-profile-form-6" class="form-label">
                    {{
                      page_data.tenderDetail?.tender_winning?.total_amount
                    }}</label
                  >
                </div>
                <div
                  class="mt-2"
                  v-if="page_data.tenderDetail.statusText == 'Won'"
                >
                  <label
                    for="update-profile-form-6"
                    class="form-label font-bold"
                    >Import Expenses :&nbsp;</label
                  ><label for="update-profile-form-6" class="form-label">
                    <a
                      href="javascript:;"
                      data-toggle="modal"
                      data-target="#import-detail-modal"
                      >{{ page_data.tenderDetail?.total_expense }}</a
                    ></label
                  >
                </div>
                <div
                  class="mt-2"
                  v-if="page_data.tenderDetail.statusText == 'Won'"
                >
                  <label
                    for="update-profile-form-6"
                    class="form-label font-bold"
                    >Finance Expenses :&nbsp;</label
                  ><label for="update-profile-form-6" class="form-label">
                    <a
                      href="javascript:;"
                      data-toggle="modal"
                      data-target="#finance-detail-modal"
                      >{{ page_data.finance_expenses ?? 0 }}</a
                    ></label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="intro-y box my-5 py-5">
          <div class="col-span-12 xl:col-span-12">
            <div
              class="flex flex-col sm:flex-row items-center pt-2 p-5 border-b border-gray-200 dark:border-dark-5"
            >
              <h2 class="font-medium text-base mr-auto pl-5">Lot Details</h2>
            </div>
            <div class="m-5">
              <div class="border">
                <div class="overflow-x-auto">
                  <table class="w-full text-center text-xs">
                    <thead>
                      <tr class="bg-theme-14">
                        <th
                          class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5"
                        >
                          Lot. No
                        </th>
                        <th
                          class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5"
                        >
                          Weight
                        </th>
                        <th
                          class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5"
                        >
                          Result
                        </th>
                        <th
                          class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5"
                        >
                          Color
                        </th>
                        <th
                          class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5"
                        >
                          Purity
                        </th>
                        <th
                          class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5"
                        >
                          Blue UV
                        </th>
                        <th
                          class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5"
                        >
                          Brown
                        </th>
                        <th
                          class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5"
                        >
                          Comment
                        </th>
                        <th
                          class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5"
                        >
                          Flow
                        </th>
                        <th
                          class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5"
                        >
                          Tension
                        </th>
                        <th
                          class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5"
                        >
                          Eyeclc-R
                        </th>
                        <th
                          class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5"
                        >
                          Eyeclc-F
                        </th>
                        <th
                          class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5"
                        >
                          Center
                        </th>
                        <th
                          class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5"
                        >
                          Price/Ct
                        </th>
                        <th
                          class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5"
                        >
                          Total Price
                        </th>
                        <th
                          class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5"
                        >
                          Total Plans
                        </th>
                        <th
                          class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5"
                        >
                          Video
                        </th>
                        <th
                          class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5"
                        >
                          Cap
                        </th>
                        <th
                          class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5"
                        >
                          Image
                        </th>
                        <th
                          class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5"
                        >
                          Status
                        </th>
                        <th
                          class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5"
                        >
                          Plan
                        </th>
                      </tr>
                    </thead>
                    <tbody
                      class="text-center"
                      v-for="(field, index) in page_data.tenderDetail.lot_items"
                      :key="index"
                    >
                      <tr
                        class="hover:bg-gray-200"
                        :class="{ 'bg-gray-100': index % 2 == 0 }"
                      >
                        <td
                          class="border-b border-r py-2 px-2 whitespace-nowrap"
                        >
                          {{ field.lot_no }}
                        </td>
                        <td
                          class="border-b border-r py-2 px-2 whitespace-nowrap"
                        >
                          {{ field.weight }}
                        </td>
                        <td
                          class="border-b border-r py-2 px-2 whitespace-nowrap"
                        >
                          {{
                            field.result_item
                              ? field.result_item.color_name
                              : field.result == 'no_result'
                              ? '???'
                              : '-'
                          }}
                        </td>
                        <td
                          class="border-b border-r py-2 px-2 whitespace-nowrap"
                        >
                          {{
                            field.color_item
                              ? field.color_item.color_name
                              : field.color == 'no_result'
                              ? '???'
                              : '-'
                          }}
                        </td>
                        <td
                          class="border-b border-r py-2 px-2 whitespace-nowrap"
                        >
                          {{
                            field.purity_item
                              ? field.purity_item.purity_name
                              : field.purity == 'no_result'
                              ? '???'
                              : '-'
                          }}
                        </td>
                        <td
                          class="border-b border-r py-2 px-2 whitespace-nowrap"
                        >
                          {{ field.blue_uv }}
                        </td>
                        <td
                          class="border-b border-r py-2 px-2 whitespace-nowrap"
                        >
                          {{
                            field.brown_item
                              ? field.brown_item.brown_name
                              : field.brown == 'no_result'
                              ? '???'
                              : '-'
                          }}
                        </td>
                        <td
                          class="border-b border-r py-2 px-2 whitespace-nowrap"
                        >
                          {{
                            field.comment_item
                              ? field.comment_item.comment_name
                              : field.comment == 'no_result'
                              ? '???'
                              : '-'
                          }}
                        </td>
                        <td
                          class="border-b border-r py-2 px-2 whitespace-nowrap"
                        >
                          {{
                            field.flow_item
                              ? field.flow_item.fluerocent_name
                              : field.flow == 'no_result'
                              ? '???'
                              : '-'
                          }}
                        </td>
                        <td
                          class="border-b border-r py-2 px-2 whitespace-nowrap"
                        >
                          {{
                            field.tension_item
                              ? field.tension_item.tension_name
                              : field.tension == 'no_result'
                              ? '???'
                              : '-'
                          }}
                        </td>
                        <td
                          class="border-b border-r py-2 px-2 whitespace-nowrap"
                        >
                          {{
                            field.eyeclc_r_item
                              ? field.eyeclc_r_item.color_name
                              : field.eyeclc_r == 'no_result'
                              ? '???'
                              : '-'
                          }}
                        </td>
                        <td
                          class="border-b border-r py-2 px-2 whitespace-nowrap"
                        >
                          {{
                            field.eyeclc_f_item
                              ? field.eyeclc_f_item.color_name
                              : field.eyeclc_f == 'no_result'
                              ? '???'
                              : '-'
                          }}
                        </td>
                        <td
                          class="border-b border-r py-2 px-2 whitespace-nowrap"
                        >
                          {{
                            field.center_item
                              ? field.center_item.purity_name
                              : field.center == 'no_result'
                              ? '???'
                              : '-'
                          }}
                        </td>
                        <td
                          class="border-b border-r py-2 px-2 whitespace-nowrap"
                        >
                          {{ field.price_ct ? field.price_ct + ' Ct' : '-' }}
                        </td>
                        <td
                          class="border-b border-r py-2 px-2 whitespace-nowrap"
                        >
                          {{
                            field.total_price ? field.total_price + ' Ct' : '-'
                          }}
                        </td>
                        <td
                          class="border-b border-r py-2 px-2 whitespace-nowrap"
                        >
                          {{ field.tender_plans ? field.tender_plans.length : 0 }}
                        </td>
                        <td
                          class="border-b border-r py-2 px-2 whitespace-nowrap"
                        >
                          <a target="_blank" :href="baseURL + field.video"
                            >Video</a
                          >
                        </td>
                        <td
                          class="border-b border-r py-2 px-2 whitespace-nowrap"
                        >
                          <a target="_blank" :href="baseURL + field.cap">Cap</a>
                        </td>
                        <td
                          class="border-b border-r py-2 px-2 whitespace-nowrap"
                        >
                          <a target="_blank" :href="baseURL + field.image"
                            >Image</a
                          >
                        </td>
                        <td>
                          <span
                            class="text-xs font-medium me-2 px-2.5 py-0.5 rounded"
                            :class="{
                              'bg-red-100 text-red-800':
                                field.active_status === 3 ||
                                (field.active_status !== 1 &&
                                  field.active_status !== 2),
                              'bg-blue-100 text-blue-800':
                                field.active_status === 2,
                              'bg-green-100 text-green-800':
                                field.active_status === 1
                            }"
                          >
                            {{
                              field.active_status === 1
                                ? 'Active'
                                : field.active_status === 2
                                ? 'Won'
                                : field.active_status === 3
                                ? 'Cancelled'
                                : 'Expired'
                            }}
                          </span>
                        </td>

                        <td class="whitespace-nowrap">
                          <a class="text-primary-1" href="javascript:;" @click="new_planning(field)" v-if="field.active_status === 1">
                            <span
                              class="text-xs font-medium me-2 px-2.5 py-0.5 rounded bg-blue-100 text-blue-800"
                            >
                              New Plan
                            </span></a
                          >
                          <a class="text-primary-4 ml-2" href="javascript:;"
                          data-toggle="modal"
                          data-target="#view-manual-planning" @click="view_planning(field)">
                            <span
                              class="text-xs font-medium me-2 px-2.5 py-0.5 rounded bg-green-100 text-green-800"
                            >
                              View Plan
                            </span></a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!-- <div
                    class="text-center py-2"
                    v-if="page_data.tenderDetail.lot_items.length == 0"
                  >
                    Records not Found
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="expense-detail-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <a data-dismiss="modal" href="javascript:;">
            <XIcon class="w-8 h-8 text-gray-500" />
          </a>
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">Expense Detail</h2>
          </div>
          <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
            <div class="col-span-12">
              <div class="m-5">
                <div class="border">
                  <div class="overflow-x-auto">
                    <table class="w-full text-center text-xs">
                      <thead>
                        <tr class="bg-theme-14">
                          <th
                            class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5"
                          >
                            Sr. No
                          </th>
                          <th
                            class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5"
                          >
                            Expense Name
                          </th>
                          <th
                            class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5"
                          >
                            Amount
                          </th>
                          <th
                            class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5"
                          >
                            File
                          </th>
                        </tr>
                      </thead>
                      <tbody
                        class="text-center"
                        v-for="(field, index) in page_data.tenderDetail
                          .expense_items"
                        :key="index"
                      >
                        <tr
                          class="hover:bg-gray-200"
                          :class="{ 'bg-gray-100': index % 2 == 0 }"
                        >
                          <td
                            class="border-b border-r py-2 px-2 whitespace-nowrap"
                          >
                            {{ index + 1 }}
                          </td>
                          <td
                            class="border-b border-r py-2 px-2 whitespace-nowrap"
                          >
                            {{ field.expense?.expense_name }}
                          </td>
                          <td
                            class="border-b border-r py-2 px-2 whitespace-nowrap"
                          >
                            {{ field.amount }}
                          </td>
                          <td
                            class="border-b border-r py-2 px-2 whitespace-nowrap"
                          >
                            <a target="_blank" :href="baseURL + field.file"
                              >File</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <!-- <div
                    class="text-center py-2"
                    v-if="page_data.tenderDetail.lot_items.length == 0"
                  >
                    Records not Found
                  </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="import-detail-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <a data-dismiss="modal" href="javascript:;">
            <XIcon class="w-8 h-8 text-gray-500" />
          </a>
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">
              Import Expenses Detail
            </h2>
          </div>
          <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
            <div class="col-span-12">
              <div class="m-5">
                <div class="border">
                  <div class="overflow-x-auto">
                    <table class="w-full text-center text-xs">
                      <thead>
                        <tr class="bg-theme-14">
                          <th
                            class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5"
                          >
                            Sr. No
                          </th>
                          <th
                            class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5"
                          >
                            Expense Name
                          </th>
                          <th
                            class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5"
                          >
                            Amount
                          </th>
                        </tr>
                      </thead>
                      <tbody
                        class="text-center"
                        v-for="(field, index) in page_data.tenderDetail
                          ?.tender_winning?.import_expense_items"
                        :key="index"
                      >
                        <tr
                          class="hover:bg-gray-200"
                          :class="{ 'bg-gray-100': index % 2 == 0 }"
                        >
                          <td
                            class="border-b border-r py-2 px-2 whitespace-nowrap"
                          >
                            {{ index + 1 }}
                          </td>
                          <td
                            class="border-b border-r py-2 px-2 whitespace-nowrap"
                          >
                            {{ field.expense?.expense_name }}
                          </td>
                          <td
                            class="border-b border-r py-2 px-2 whitespace-nowrap"
                          >
                            {{ field.amount }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <!-- <div
                    class="text-center py-2"
                    v-if="page_data.tenderDetail.lot_items.length == 0"
                  >
                    Records not Found
                  </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="finance-detail-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <a data-dismiss="modal" href="javascript:;">
            <XIcon class="w-8 h-8 text-gray-500" />
          </a>
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">
              Finance Expenses Detail
            </h2>
          </div>
          <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
            <div class="col-span-12">
              <div class="m-5">
                <div class="border">
                  <div class="overflow-x-auto">
                    <table class="w-full text-center text-xs">
                      <thead>
                        <tr class="bg-theme-14">
                          <th
                            class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5"
                          >
                            Sr. No
                          </th>
                          <th
                            class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5"
                          >
                            Expense Name
                          </th>
                          <th
                            class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5"
                          >
                            Amount
                          </th>
                        </tr>
                      </thead>
                      <tbody
                        class="text-center"
                        v-for="(field, index) in page_data.tenderDetail
                          ?.tender_winning?.finance_expense_items"
                        :key="index"
                      >
                        <tr
                          class="hover:bg-gray-200"
                          :class="{ 'bg-gray-100': index % 2 == 0 }"
                        >
                          <td
                            class="border-b border-r py-2 px-2 whitespace-nowrap"
                          >
                            {{ index + 1 }}
                          </td>
                          <td
                            class="border-b border-r py-2 px-2 whitespace-nowrap"
                          >
                            {{ field.expense?.expense_name }}
                          </td>
                          <td
                            class="border-b border-r py-2 px-2 whitespace-nowrap"
                          >
                            {{ field.amount }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <!-- <div
                    class="text-center py-2"
                    v-if="page_data.tenderDetail.lot_items.length == 0"
                  >
                    Records not Found
                  </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="new-manual-planning" ref="new-manual-planning" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog-lg">
        <div class="modal-content">
          <div class="modal-header">
            <div class="flex justify-between items-center w-full">

              <h2 class="font-medium text-base mr-auto">
                New Planning (Lot No: {{ page_data.planning_packet.lot_no }}) (Weight {{page_data.planning_packet.weight}} Ct)
              </h2>
              <a data-dismiss="modal" href="javascript:;">
                <XIcon class="w-8 h-8 text-gray-500" />
              </a>
            </div>
          </div>
          <div class="modal-body">


            <div class="w-full overflow-scroll">

              <table class="w-full border">
                <thead>
                  <tr>
                    <th class="whitespace-nowrap px-2">No</th>
                    <th class="whitespace-nowrap px-2">PL No</th>
                    <th class="whitespace-nowrap px-2">PL Wt</th>
                    <th class="whitespace-nowrap px-2">Shape</th>
                    <th class="whitespace-nowrap px-2">Color</th>
                    <th class="whitespace-nowrap px-2">Clarity</th>
                    <th class="whitespace-nowrap px-2">Diamond Type</th>
                    <th class="whitespace-nowrap px-2">Diamond (%)</th>
                    <th class="whitespace-nowrap px-2">Inclusion Type</th>
                    <th class="whitespace-nowrap px-2">Inclusion (%)</th>
                    <th class="whitespace-nowrap px-2">Brown</th>
                    <th class="whitespace-nowrap px-2">Brown(%)</th>
                    <th class="whitespace-nowrap px-2">Cut</th>
                    <th class="whitespace-nowrap px-2">Cut(%)</th>
                    <th class="whitespace-nowrap px-2">Polsh</th>
                    <th class="whitespace-nowrap px-2">Symm</th>
                    <th class="whitespace-nowrap px-2">Symm(%)</th>
                    <th class="whitespace-nowrap px-2">Flow</th>
                    <th class="whitespace-nowrap px-2">Flow(%)</th>
                    <th class="whitespace-nowrap px-2">Ratio</th>
                    <th class="whitespace-nowrap px-2">Table</th>
                    <th class="whitespace-nowrap px-2">Depth</th>
                    <th class="whitespace-nowrap px-2">Back(%)</th>
                    <th class="whitespace-nowrap px-2">FInal Back(%)</th>
                    <th class="whitespace-nowrap px-2">R Rate ($)</th>
                    <th class="whitespace-nowrap px-2">Per / Ct ($)</th>
                    <th class="whitespace-nowrap px-2">Value ($)</th>
                    <th class="whitespace-nowrap px-2"></th>
                  </tr>
                  <!-- <tr>
                    <th></th>
                    <th></th>
                  </tr> -->
                </thead>
                <tbody>
                  <tr class="border" v-for="(plan,index) in page_data.manualPlanning" :key="index">
                    <td>
                      <p>{{ index + 1 }}</p>
                    </td>
                    <td>
                      <input v-model="plan.part_no" type="text"
                        class="form-control w-16" placeholder="Part No" onpaste="return false;">
                    </td>
                    <td>
                      <input v-model="plan.polish_weight" type="text"
                      @change="loadPlanningValues(plan, 'repo')"
                        class="form-control w-24" placeholder="Polish Wt" onpaste="return false;">
                    </td>
                    <td>
                      <select
                          class="form-select w-auto"
                          v-model="plan.shape"
                          @change="loadPlanningValues(plan, 'repo')"
                          aria-label="Default select example"
                        >
                        <option :value="shape.id" v-for="shape in diamond.shapes" :key="shape.id">{{ shape.shape_name }}</option>
                      </select>
                    </td>
                    <td>
                      <select
                          class="form-select w-auto"
                          v-model="plan.color"
                          @change="loadPlanningValues(plan, 'repo')"
                          aria-label="Default select example"
                        >
                        <option :value="shape.id" v-for="shape in diamond.colors" :key="shape.id">{{ shape.color_name }}</option>
                      </select>
                    </td>
                    <td>
                      <select
                          class="form-select w-auto"
                          v-model="plan.purity"
                          @change="loadPlanningValues(plan, 'repo')"
                          aria-label="Default select example"
                        >
                        <option :value="shape.id" v-for="shape in diamond.purities" :key="shape.id">{{ shape.purity_name }}</option>
                      </select>
                    </td>
                    <td>
                      <select
                          class="form-select w-auto"
                          v-model="plan.diamond_type"
                          @change="loadPlanningValues(plan, 'diamond_type')"
                          aria-label="Default select example"
                        >
                        <option :value="shape.id" v-for="shape in diamond.diamond_types" :key="shape.id">{{ shape.type }}</option>
                      </select>
                    </td>
                    <td>
                      <input v-model="plan.diamond_type_discount" @change="loadPlanningValues(plan, 'repo')" type="text"
                        class="form-control w-24" placeholder="" onpaste="return false;">
                    </td>
                    <td>
                      <select
                          class="form-select w-auto"
                          v-model="plan.inclusion_type"
                          @change="loadPlanningValues(plan, 'inclusion_type')"
                          aria-label="Default select example"
                        >
                        <option :value="shape.id" v-for="shape in diamond.inclusion_types" :key="shape.id">{{ shape.type }}</option>
                      </select>
                    </td>
                    <td>
                      <input v-model="plan.inclusion_type_discount" @change="loadPlanningValues(plan, 'repo')" type="text"
                        class="form-control w-24" placeholder="" onpaste="return false;">
                    </td>
                    <td>
                      <select
                          class="form-select w-auto"
                          v-model="plan.brown"
                          @change="loadPlanningValues(plan, 'brown')"
                          aria-label="Default select example"
                        >
                        <option :value="shape.id" v-for="shape in diamond.browns" :key="shape.id">{{ shape.brown_name }}</option>
                      </select>
                    </td>
                    <td>
                      <input v-model="plan.brown_discount" @change="loadPlanningValues(plan, 'repo')" type="text"
                        class="form-control w-24" placeholder="" onpaste="return false;">
                    </td>
                    <td>
                      <select
                          class="form-select w-auto"
                           @change="loadPlanningValues(plan, 'cut')"
                          v-model="plan.cut"
                          aria-label="Default select example"
                        >
                        <option :value="shape.id" v-for="shape in diamond.cuts" :key="shape.id">{{ shape.cut_name }}</option>
                      </select>
                    </td>
                    <td>
                      <input v-model="plan.cut_discount" @change="loadPlanningValues(plan, 'repo')" type="text"
                        class="form-control w-24" placeholder="" onpaste="return false;">
                    </td>
                    <td>
                      <select
                          class="form-select w-auto"
                          v-model="plan.polish"
                          aria-label="Default select example"
                        >
                        <option :value="shape.id" v-for="shape in diamond.polish" :key="shape.id">{{ shape.polish_name }}</option>
                      </select>
                    </td>
                    <td>
                      <select
                          class="form-select w-auto"
                          v-model="plan.symmetry"
                          aria-label="Default select example"
                          @change="loadPlanningValues(plan, 'symmetry')"
                        >
                        <option :value="shape.id" v-for="shape in diamond.symmetry" :key="shape.id">{{ shape.symmetry_name }}</option>
                      </select>
                    </td>
                    <td>
                      <input v-model="plan.symmetry_discount" @change="loadPlanningValues(plan, 'repo')" type="text"
                        class="form-control w-24" placeholder="" onpaste="return false;">
                    </td>
                    <td>
                      <select
                          class="form-select w-auto"
                          v-model="plan.fluerocent"
                          aria-label="Default select example"
                          @change="loadPlanningValues(plan, 'fluerocent')"
                        >
                        <option :value="shape.id" v-for="shape in diamond.fluerocents" :key="shape.id">{{ shape.fluerocent_name }}</option>
                      </select>
                    </td>
                    <td>
                      <input v-model="plan.fluerocent_discount" @change="loadPlanningValues(plan, 'repo')" type="text"
                        class="form-control w-24" placeholder="" onpaste="return false;">
                    </td>
                    <td>
                      <select
                          class="form-select w-auto"
                          v-model="plan.ratio"
                           @change="loadPlanningValues(plan, 'repo')"
                          aria-label="Default select example"
                        >
                        <option :value="ratio.id" v-for="ratio in diamond.ratios" :key="ratio.id">{{ ratio.ratio_name }}</option>
                      </select>
                    </td>
                    <td>
                      <select
                          class="form-select"
                          v-model="plan.tabl"
                           @change="loadPlanningValues(plan, 'repo')"
                          aria-label="Default select example"
                        >
                        <option :value="tabl.id" v-for="tabl in diamond.tabls" :key="tabl.id">{{ tabl.tabl_name }}</option>
                      </select>
                    </td>
                    <td>
                      <select
                          class="form-select"
                          v-model="plan.height"
                           @change="loadPlanningValues(plan, 'repo')"
                          aria-label="Default select example"
                        >
                        <option :value="height.id" v-for="height in diamond.heights" :key="height.id">{{ height.height_name }}</option>
                      </select>
                    </td>
                    <td>
                      <input v-model="plan.discount" type="text" disabled @input="calculate_value(plan)"
                      class="form-control w-24" placeholder="Back" @keypress="$h.preventInvalidInputInt" onpaste="return false;">
                    </td>
                    <td>
                      <input v-model="plan.final_discount" type="text" disabled @input="calculate_value(plan)"
                      class="form-control w-24" placeholder="Back" @keypress="$h.preventInvalidInputInt" onpaste="return false;">
                    </td>
                    <td class="whitespace-nowrap">
                      <input v-model="plan.r_rate" type="text" disabled
                        class="form-control w-24" placeholder="R rate" @keypress="$h.preventInvalidInputInt" onpaste="return false;">
                        <button class="btn p-1 mx-1" :disabled="plan.polish_weight < 10" :class="{'btn-primary' : plan.size == 5, 'btn-outline-primary hover:text-white' : plan.size != 5}" @click="updateSize(5, plan)"> 5+</button>
                        <button class="btn p-1 mx-1" :disabled="plan.polish_weight < 10" :class="{'btn-primary' : plan.size == 10, 'btn-outline-primary hover:text-white' : plan.size != 10}" @click="updateSize(10, plan)"> 10+</button>
                    </td>
                    <td>
                      <input v-model="plan.per_ct" type="text" disabled
                        class="form-control w-24" placeholder="Value" onpaste="return false;">
                    </td>
                    <td>
                      <input v-model="plan.value" type="text" disabled
                        class="form-control w-24" placeholder="Value" onpaste="return false;">
                    </td>
                    <td>
                      <button type="button" class="text-theme-6 flex items-center" tabindex="-1"  @click="removeField(plan.id)">
                        <Trash2Icon class="w-5 h-5 mr-1" tabindex="-1"/>
                      </button>
                    </td>
                  </tr>
                </tbody>
                <tbody>
                  <tr class="border">
                    <td>
                    </td>
                    <td></td>
                    <td :class="{'text-theme-6' : $h.myFloat(page_data.menual_total.polish_weight ? page_data.menual_total.polish_weight : 0) > $h.myFloat(page_data.planning_packet.weight)}">
                      <p>{{ page_data.menual_total.polish_weight }}</p>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td> <p>{{ $h.myFloat(page_data.menual_total.value ? page_data.menual_total.value : 0) }}</p></td>
                  </tr>
                </tbody>


              </table>
            </div>

            <div>
                <button type="button" @click="add_plan_fields" class="btn btn-primary btn-sm ml-5 mt-5 mb-5 mr-auto">
                  New Part Planning
                </button>
            </div>

            <div class="grid grid-cols-12 items-center gap-4 gap-y-3 text-right py-1">

              <div class="col-span-7"></div>
              <div class="col-span-1">
              </div>
              <div class="col-span-2 pr-1">
                <b>Total Value :</b>
              </div>
              <div class="col-span-2 pr-1 border-b">
                <b>{{  page_data.menual_total.value.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</b>
              </div>
            </div>
            <div class="grid grid-cols-12 items-center gap-4 gap-y-3 text-right py-1">

              <div class="col-span-7"></div>
              <div class="col-span-1 text-right">
              </div>
              <div class="col-span-2">
                <b>Labour(Per/Ct $) :</b>
              </div>
              <div class="col-span-2 pr-1 border-b">
                <div class="flex justify-between items-center">
                  <div>

                    <input v-model="page_data.menual_total.labour_per_ct" type="text" @keypress="$h.preventInvalidInput" @input="getPlanningTotal"
                      class="form-control p-0 px-1 w-14 text-center" placeholder="" onpaste="return false;">
                  </div>
                  <div>
                    {{page_data.menual_total.total_labour ? page_data.menual_total.total_labour.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : 0}}
                  </div>
                </div>
              </div>
            </div>
            <div class="grid grid-cols-12 items-center gap-4 gap-y-3 text-right py-1">

              <div class="col-span-7"></div>
              <div class="col-span-1"></div>
              <div class="col-span-2">
                <b>Profit(%) :</b>
              </div>
              <div class="col-span-2 pr-1 border-b">
                <div class="flex justify-between items-center">
                  <div>

                    <input v-model="page_data.menual_total.profit" type="text" @keypress="$h.preventInvalidInput" @input="getPlanningTotal"
                          class="form-control p-0 px-1 w-14 text-center" placeholder="" onpaste="return false;">
                  </div>
                  <div>
                    {{page_data.menual_total.profit_amount ? page_data.menual_total.profit_amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : 0}}
                  </div>
                </div>
              </div>
            </div>
            <div class="grid grid-cols-12 items-center gap-4 gap-y-3 text-right py-1">

              <div class="col-span-7"></div>
              <div class="col-span-1">
                <b></b>
              </div>
              <div class="col-span-2 pr-1">
                <b>New Value :</b>
              </div>
              <div class="col-span-2 pr-1 border-b">
                <b>{{ page_data.menual_total.value_profit.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</b>
              </div>
            </div>
            <div class="grid grid-cols-12 items-center gap-4 gap-y-3 text-right py-1">

              <div class="col-span-7"></div>
              <div class="col-span-1"></div>
              <div class="col-span-2">
                <b>Interest($) :</b>
              </div>
              <div class="col-span-2 pr-1 border-b">
                <div class="flex justify-between items-center">
                  <div>

                    <input v-model="page_data.menual_total.interest" type="text" @keypress="$h.preventInvalidInput" @input="getPlanningTotal"
                      class="form-control p-0 w-8 text-center" placeholder="" onpaste="return false;">%
                    <input v-model="page_data.menual_total.interest_duration" type="text" @keypress="$h.preventInvalidInput" @input="getPlanningTotal"
                      class="form-control p-0 w-8 text-center" placeholder="" onpaste="return false;">M
                  </div>
                  <div>
                    {{page_data.menual_total.interest_amount ? page_data.menual_total.interest_amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : 0}}
                  </div>
                </div>
              </div>
            </div>
            <div class="grid grid-cols-12 items-center gap-4 gap-y-3 text-right py-1">

              <div class="col-span-7"></div>
              <div class="col-span-1">
                <b></b>
              </div>
              <div class="col-span-2 pr-1">
                <b>New Value :</b>
              </div>
              <div class="col-span-2 pr-1 border-b">
                <b>{{ page_data.menual_total.value_interest.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</b>
              </div>
            </div>
            <div class="grid grid-cols-12 items-center gap-4 gap-y-3 text-right py-1">

              <div class="col-span-7"></div>
              <div class="col-span-1">
              </div>
              <div class="col-span-2">
                <b>Duty(%) :</b>
              </div>
              <div class="col-span-2 pr-1 border-b">
                <div class="flex justify-between items-center">
                  <div>

                    <input v-model="page_data.menual_total.duty" type="text" @keypress="$h.preventInvalidInput" @input="getPlanningTotal"
                      class="form-control p-0 px-1 w-14 text-center" placeholder="" onpaste="return false;">
                  </div>
                  <div>
                    {{page_data.menual_total.duty_amount ? page_data.menual_total.duty_amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : 0}}
                  </div>
                </div>
              </div>
            </div>
            <div class="grid grid-cols-12 items-center gap-4 gap-y-3 text-right py-1">

              <div class="col-span-7"></div>
              <div class="col-span-1">
              </div>
              <div class="col-span-2">
                <b>Extra Expense($) :</b>
              </div>
              <div class="col-span-2 pr-1 border-b">
                <div class="flex justify-between items-center">
                  <div>


                    </div>
                    <div>
                    <input v-model="page_data.menual_total.extra_expense" type="text" @keypress="$h.preventInvalidInput" @input="getPlanningTotal"
                    class="form-control p-0 px-2 w-full text-right" placeholder="" onpaste="return false;">
                  </div>
                </div>
              </div>
            </div>
            <div class="grid grid-cols-12 items-center gap-4 gap-y-3 text-right py-1">

              <div class="col-span-7"></div>
              <div class="col-span-1">
              </div>
              <div class="col-span-2">
                <b>Final Price($) :</b>
              </div>
              <div class="col-span-2 pr-1 border-b">
                {{page_data.menual_total.final_value ? page_data.menual_total.final_value.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : 0}}
              </div>
            </div>

            <div class="grid grid-cols-12 items-center gap-4 gap-y-3 text-right py-1">

              <div class="col-span-7"></div>
              <div class="col-span-1">
              </div>
              <div class="col-span-2">
                <b>Tender Amount($) :</b>
              </div>
              <div class="col-span-2 pr-1 border-b">
                <div class="flex justify-between items-center">
                  <div>


                    </div>
                    <div>
                    <input v-model="page_data.menual_total.tender_amount" type="text" @keypress="$h.preventInvalidInput"
                      class="form-control p-0 px-2 w-full text-right" placeholder="" onpaste="return false;">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer text-right">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-32 mr-1"
            >
              Cancel
            </button>
            <button type="button" @click="submitManualPlanning" :disabled="page_data.loading" class="btn btn-primary">Save Details
              <LoadingIcon v-if="page_data.loading" icon="oval" color="white" class="w-4 h-4 ml-2" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <div id="view-manual-planning" ref="view-manual-planning" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog-lg">
        <div class="modal-content">
          <div class="modal-header flex justify-between items-center">
            <div class="flex justify-between items-center w-full">

              <h2 class="font-medium text-base mr-auto">
                View Plans (Lot No: {{ page_data.planning_view.lot_no }}) (Weight {{page_data.planning_view.weight}} Ct)
              </h2>
              <a data-dismiss="modal" href="javascript:;">
                <XIcon class="w-8 h-8 text-gray-500" />
              </a>
            </div>
          </div>
          <div class="modal-body">

            <div class="border-b mt-5" v-for="(plan_detail, index) in page_data.planning_view.tender_plans">


              <div class="grid grid-cols-12 gap-5">

                <div class="col-span-12">
                      <div class="flex justify-between items-center">
                        <div class="flex flex-wrap">

                          <div class="text-sm font-bold mr-4">Plan No: {{ index + 1 }}</div>
                          <div class="text-sm font-bold mr-4">Polish WT(Ct): {{ plan_detail.polish_weight ? $h.myFloat(plan_detail.polish_weight) : 0 }} Ct</div>
                          <!-- <div class="text-sm font-bold mr-4">Avg Discount(%): {{ plan_detail.final_value ? $h.myFloat((plan_detail.value - plan_detail.final_value) * 100 / plan_detail.polish_weight ) : 0 }} %</div> -->
                          <div class="text-sm font-bold mr-4">Per/Ct($): {{ plan_detail.per_ct ? $h.myFloat(plan_detail.per_ct) : 0 }} Ct</div>
                          <div class="text-sm font-bold">Final Price($): {{ plan_detail.final_value ? $h.myFloat(plan_detail.final_value) : 0 }} $</div>
                        </div>
                        <div class="flex items-center justify-end">
                          <a class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded" href="javascript:;" @click="update_planning(plan_detail)">
                            <CheckSquareIcon class="w-3 h-3 mr-1" />
                            Edit
                          </a>
                          <a class="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded" href="javascript:;" @click="cancel_plan(plan_detail.id)">
                            <TrashIcon class="w-3 h-3 mr-1" />
                            Delete
                          </a>
                        </div>

                      </div>
                  </div>
              </div>

              <table class="w-full text-center text-xs my-5">
                <thead>
                  <tr class="bg-theme-14">
                    <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">No</th>
                    <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">PL No</th>
                    <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">PL Wt</th>
                    <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Shp</th>
                    <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Color</th>
                    <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Clarity</th>
                    <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Dismond Type</th>
                    <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Inclusion Type</th>
                    <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Brown</th>
                    <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Cut</th>
                    <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Polsh</th>
                    <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Symm</th>
                    <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Flow</th>
                    <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Ratio</th>
                    <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Table</th>
                    <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Depth</th>
                    <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Back (%)</th>
                    <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">FInalBack (%)</th>
                    <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">R Rate ($)</th>
                    <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Per / Ct ($)</th>
                    <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Value ($)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="hover:bg-gray-200"
                        :class="{ 'bg-gray-100': inx % 2 == 0 }" v-for="(plan,inx) in plan_detail.plan_detail" :key="index">
                    <td class="border-b border-r py-2 px-2 whitespace-nowrap"><p>{{ inx + 1 }}</p></td>
                    <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{plan.part_no}}</td>
                    <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{plan.polish_weight}}</td>
                    <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{plan.shape_item ? plan.shape_item.shape_name : "-"}}</td>
                    <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{plan.color_item ? plan.color_item.color_name : "-"}}</td>
                    <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{plan.purity_item ? plan.purity_item.purity_name : "-"}}</td>
                    <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{plan.cut_item ? plan.cut_item.cut_name : "-"}} {{ plan.cut_discount ? (' (' + plan.cut_discount + '%)') : "" }}</td>
                    <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{plan.polish_item ? plan.polish_item.polish_name : "-"}}</td>
                    <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{plan.diamond_type_item ? plan.diamond_type_item.type : "-"}} {{ plan.diamond_type_discount ? ' (' + plan.diamond_type_discount + '%)' : "" }}</td>
                    <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{plan.inclusion_type_item ? plan.inclusion_type_item.type : "-"}} {{ plan.inclusion_type_discount ? ' (' + plan.inclusion_type_discount + '%)' : "" }}</td>
                    <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{plan.brown_item ? plan.brown_item.type : "-"}} {{ plan.brown_discount ? ' (' + plan.brown_discount + '%)' : "" }}</td>
                    <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{plan.symmetry_item ? plan.symmetry_item.symmetry_name : "-"}} {{ plan.fluerocent_discount ? ' (' + plan.fluerocent_discount + '%)' : "" }}</td>
                    <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{plan.flow_item ? plan.flow_item.fluerocent_name : "-"}} {{ plan.fluerocent_discount ? ' (' + plan.fluerocent_discount + '%)' : "" }}</td>
                    <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{plan.ratio_item ? plan.ratio_item.ratio_name : "-"}}</td>
                    <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{plan.tabl_item ? plan.tabl_item.tabl_name : "-"}}</td>
                    <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{plan.depth_item ? plan.depth_item.height_name : "-"}}</td>
                    <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{plan.discount}}</td>
                    <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{plan.final_discount}}</td>
                    <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{Number(plan.r_rate).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}}</td>
                    <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{Number(plan.per_ct).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}}</td>
                    <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{Number(plan.value).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}}</td>
                  </tr>
                </tbody>


              </table>

              <div class="grid grid-cols-12 items-center gap-4 gap-y-3 text-right py-1 mt-5">

                <div class="col-span-7"></div>
                <div class="col-span-1">
                </div>
                <div class="col-span-2 pr-1">
                  <b>Total Value :</b>
                </div>
                <div class="col-span-2 pr-1 border-b">
                  <b>{{ parseInt(plan_detail.value).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</b>
                </div>
              </div>
              <div class="grid grid-cols-12 items-center gap-4 gap-y-3 text-right py-1">

                <div class="col-span-7"></div>
                <div class="col-span-1 text-right">
                </div>
                <div class="col-span-2">
                  <b>Labour(Per/Ct $) :</b>
                </div>
                <div class="col-span-2 pr-1 border-b">
                  <div class="flex justify-between items-center">
                    <div>

                        $ {{plan_detail.labour_per_ct ? $h.myFloat(plan_detail.labour_per_ct) : 0}}
                    </div>
                    <div>
                      {{plan_detail.total_labour ? parseInt(plan_detail.total_labour).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : 0}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid grid-cols-12 items-center gap-4 gap-y-3 text-right py-1">

                <div class="col-span-7"></div>
                <div class="col-span-1"></div>
                <div class="col-span-2">
                  <b>Profit(%) :</b>
                </div>
                <div class="col-span-2 pr-1 border-b">
                  <div class="flex justify-between items-center">
                    <div>

                            {{plan_detail.profit ? $h.myFloat(plan_detail.profit) : 0}} %
                    </div>
                    <div>
                      {{plan_detail.profit_amount ? parseInt(plan_detail.profit_amount).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : 0}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid grid-cols-12 items-center gap-4 gap-y-3 text-right py-1">

                <div class="col-span-7"></div>
                <div class="col-span-1">
                  <b></b>
                </div>
                <div class="col-span-2 pr-1">
                  <b>New Value :</b>
                </div>
                <div class="col-span-2 pr-1 border-b">
                  <b>{{ parseInt(plan_detail.value_profit).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</b>
                </div>
              </div>
              <div class="grid grid-cols-12 items-center gap-4 gap-y-3 text-right py-1">

                <div class="col-span-7"></div>
                <div class="col-span-1"></div>
                <div class="col-span-2">
                  <b>Interest($) :</b>
                </div>
                <div class="col-span-2 pr-1 border-b">
                  <div class="flex justify-between items-center">
                    <div>

                        ({{plan_detail.interest ? $h.myFloat(plan_detail.interest) : 0}} %)
                       ( {{plan_detail.interest_duration ? $h.myFloat(plan_detail.interest_duration) : 0}} M)
                    </div>
                    <div>
                      {{plan_detail.interest_amount ? parseInt(plan_detail.interest_amount).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : 0}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid grid-cols-12 items-center gap-4 gap-y-3 text-right py-1">

                <div class="col-span-7"></div>
                <div class="col-span-1">
                  <b></b>
                </div>
                <div class="col-span-2 pr-1">
                  <b>New Value :</b>
                </div>
                <div class="col-span-2 pr-1 border-b">
                  <b>{{ parseInt(plan_detail.value_interest).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</b>
                </div>
              </div>
              <div class="grid grid-cols-12 items-center gap-4 gap-y-3 text-right py-1">

                <div class="col-span-7"></div>
                <div class="col-span-1">
                </div>
                <div class="col-span-2">
                  <b>Duty(%) :</b>
                </div>
                <div class="col-span-2 pr-1 border-b">
                  <div class="flex justify-between items-center">
                    <div>

                        {{plan_detail.duty ? $h.myFloat(plan_detail.duty) : 0}} %
                    </div>
                    <div>
                      {{plan_detail.duty_amount ? parseInt(plan_detail.duty_amount).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : 0}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid grid-cols-12 items-center gap-4 gap-y-3 text-right py-1 text-primary-4">

                <div class="col-span-7"></div>
                <div class="col-span-1">
                </div>
                <div class="col-span-2">
                  <b>Extra Expense($) :</b>
                </div>
                <div class="col-span-2 pr-1 border-b">
                  {{plan_detail.extra_expense ? parseInt(plan_detail.extra_expense).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : 0}}
                </div>
              </div>
              <div class="grid grid-cols-12 items-center gap-4 gap-y-3 text-right py-1 text-primary-4">

                <div class="col-span-7"></div>
                <div class="col-span-1">
                </div>
                <div class="col-span-2">
                  <b>Final Price($) :</b>
                </div>
                <div class="col-span-2 pr-1 border-b">
                  {{plan_detail.final_value ? parseInt(plan_detail.final_value).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : 0}}
                </div>
              </div>
              <div class="grid grid-cols-12 items-center gap-4 gap-y-3 text-right py-1 text-primary-4">

                <div class="col-span-7"></div>
                <div class="col-span-1">
                </div>
                <div class="col-span-2">
                  <b>Tender Amount($) :</b>
                </div>
                <div class="col-span-2 pr-1 border-b">
                  {{plan_detail.tender_amount ? parseInt(plan_detail.tender_amount).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : 0}}
                </div>
              </div>
            </div>



          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  reactive,
  ref,
  watch
} from 'vue'
import axios from 'axios'
import datetime from 'vuejs-datetimepicker'
import { useRoute } from 'vue-router'
import { helper as $h } from '@/utils/helper'

import { BASE_URL } from '@/constants/config.js'

export default defineComponent({
  components: {
    datetime
  },

  setup() {
    const route = useRoute()
    const tabulator = ref()
    const baseURL = ref(BASE_URL)

    const swal = inject('$swal')

    const page_data = reactive({
      tenderDetail: '',
      finance_expenses: '',
      plan_id: 0,
      planning_view: [],
      planning_packet: [],
      manualPlanDetail: [],
      manualPlanning: [],
      menual_total: {
        weight: 0,
        polish_weight: 0,
        value: 0,
        duty: 0,
        duty_amount: 0,
        labour_per_ct: 0,
        total_labour: 0,
        profit: 0,
        profit_amount: 0,
        value_profit: 0,
        value_interest: 0,
        interest: "",
        extra_expense: "",
        tender_amount: "",
        interest_duration: "",
        interest_amount: 0,
        final_value: 0,
      },
    })

    const diamond = reactive({
      colors: [],
      cuts: [],
      purities: [],
      symmetry: [],
      polish: [],
      fluerocents: [],
      tensions: [],
      stones: [],
      shapes: [],
      heights: [],
      ratios: [],
      browns: [],
      diamond_types: [],
      inclusion_types: [],
      tabls: []
    })

    const getTenderDetails = async id => {
      const params = {
        id: id
      }
      let promise = axios({
        url: 'api/tender/view',
        method: 'POST',
        params: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            Accept: 'application/json'
          }
        }
      })
      return promise
        .then(result => result.data)
        .then(data => {
          page_data.tenderDetail = data.item

          page_data.menual_total.duty = page_data.tenderDetail.location?.duty
          page_data.finance_expenses = 0

          if (
            page_data.tenderDetail?.tender_winning?.finance_expense_items
              .length > 0
          ) {
            for (const item of page_data.tenderDetail.tender_winning
              .finance_expense_items) {
              page_data.finance_expenses += parseFloat(item.amount) || 0
            }
          }

        })
        .catch(_error => {})
    }

    const add_plan_fields = () => {
      page_data.plan_id++

      let plan_detail = {
        id: page_data.plan_id,
        part_no: '',
        shape: '',
        color: '',
        purity: '',
        cut: '',
        cut_discount: '',
        polish: '',
        symmetry: '',
        symmetry_discount: '',
        fluerocent: '',
        fluerocent_discount: '',
        tabl: '',
        height: '',
        ratio: '',
        polish_weight: '',
        discount: '',
        final_discount: '',
        brown: '',
        brown_discount: '',
        diamond_type: '',
        diamond_type_discount: '',
        inclusion_type: '',
        inclusion_type_discount: '',
        size: 5,
        r_rate: '',
        per_ct: '',
        value: ''
      }

      page_data.manualPlanning.push(plan_detail)
    }

    const removeField = id => {
      swal({
        title: 'Are you sure?',
        text: 'You are about to delete a item!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        cancelButtonText: 'Cancel',

        allowOutsideClick: () => !swal.isLoading()
      }).then(result => {
        if (result.isConfirmed) {
          var i = page_data.manualPlanning.length
          while (i--) {
            if (page_data.manualPlanning[i]['id'] == id) {
              page_data.manualPlanning.splice(i, 1)
            }
          }
        }
      })
    }

    const updateSize = (size, plan) => {

      plan.size = size
      loadPlanningValues(plan, 'repo')
    }

    const loadPlanningValues = (plan, type) => {
      getPlanningTotal()
      if(type == 'repo') {

      }
      if(type == 'diamond_type') {
        diamond.diamond_types.forEach(element => {
          if(element.id == plan.diamond_type) {
            plan.diamond_type_discount = element.discount
          }
        });
      }
      if(type == 'inclusion_type') {

        diamond.inclusion_types.forEach(element => {
          if(element.id == plan.inclusion_type) {
            plan.inclusion_type_discount = element.discount
          }
        });
      }
      if(type == 'brown') {

        diamond.browns.forEach(element => {
          if(element.id == plan.brown) {
            plan.brown_discount = element.discount
          }
        });
      }
      if(type == 'cut') {

        diamond.cuts.forEach(element => {
          if(element.id == plan.cut) {
            plan.cut_discount = element.discount
          }
        });
      }
      if(type == 'symmetry') {

        diamond.symmerty.forEach(element => {
          if(element.id == plan.symmetry) {
            plan.symmetry_discount = element.discount
          }
        });
      }
      if(type == 'fluerocent') {

        diamond.fluerocents.forEach(element => {
          if(element.id == plan.fluerocent) {
            plan.fluerocent_discount = element.discount
          }
        });
      }
      if (plan.polish_weight != '' && plan.color != '' && plan.purity != '' && plan.shape != '') {
        let shape_name = ''
        var i = diamond.shapes.length
        while (i--) {
          if (diamond.shapes[i]['id'] == plan.shape) {
            shape_name = diamond.shapes[i]['shape_group']
          }
        }
        let params = {
          color: plan.color,
          cut: plan.cut,
          purity: plan.purity,
          polish: plan.polish,
          symmetry: plan.symmetry,
          fluerocent: plan.fluerocent,
          tension: plan.tension,
          height: plan.height,
          ratio: plan.ratio,
          tabl: plan.tabl,
          shape: plan.shape,
          shape_group: shape_name,
          polish_weight: plan.polish_weight,
          stone: page_data.stone,
          size: plan.size,
        }
        axios({
          url: 'api/plan/tender_plan_values',
          method: 'post',
          baseURL: BASE_URL,
          data: params,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { Accept: 'application/json' } }
        }).then(res => {
          let rap_value = res.data.rape_value
          let back = res.data.back

          plan.r_rate = rap_value
          plan.discount = back

          calculate_value(plan);
        })
      }
    }

    const calculate_value = (plan) => {

      if(plan.r_rate != "")

        plan.r_rate = Number(plan.r_rate)

        plan.final_discount = parseFloat(parseFloat(plan.discount ? plan.discount : 0) + parseFloat(plan.cut_discount ? plan.cut_discount : 0) + parseFloat(plan.symmetry_discount ? plan.symmetry_discount : 0) + parseFloat(plan.fluerocent_discount ? plan.fluerocent_discount : 0) + parseFloat(plan.brown_discount ? plan.brown_discount : 0) + parseFloat(plan.diamond_type_discount ? plan.diamond_type_discount : 0) + parseFloat(plan.inclusion_type_discount ? plan.inclusion_type_discount : 0)).toFixed(2)
        if (plan.final_discount) {
          plan.per_ct = parseFloat(
            plan.r_rate - (plan.r_rate * (plan.final_discount ? plan.final_discount : 0)) / 100
          ).toFixed(2)
          plan.value = parseFloat(plan.per_ct * plan.polish_weight).toFixed(2)
        }

        getPlanningTotal();
    }

    const getPlanningTotal = () => {

      console.log("Calculating Values")
      let length = page_data.manualPlanning.length

      page_data.menual_total.polish_weight = 0
      page_data.menual_total.value = 0

      for (let i = 0; i < length; i++) {
        page_data.menual_total.polish_weight = parseFloat(
          parseFloat(page_data.menual_total.polish_weight) +
            parseFloat(
              page_data.manualPlanning[i]['polish_weight']
                ? page_data.manualPlanning[i]['polish_weight']
                : 0
            )
        ).toFixed(4)
        page_data.menual_total.value = parseFloat(
          parseFloat(page_data.menual_total.value) +
            parseFloat(
              page_data.manualPlanning[i]['value']
                ? page_data.manualPlanning[i]['value']
                : 0
            )
        )
      }

      page_data.menual_total.total_labour = parseInt(page_data.menual_total.polish_weight * page_data.menual_total.labour_per_ct);
      page_data.menual_total.profit_amount = parseInt(page_data.menual_total.value * page_data.menual_total.profit / 100);
      page_data.menual_total.interest_amount = parseInt(page_data.menual_total.value_profit * (page_data.menual_total.interest ? page_data.menual_total.interest : 0) * (page_data.menual_total.interest_duration ? page_data.menual_total.interest_duration : 0) / 100);
      page_data.menual_total.value_profit = parseInt(parseFloat(page_data.menual_total.value) - parseFloat(page_data.menual_total.total_labour) - parseFloat(page_data.menual_total.profit_amount));
      page_data.menual_total.value_interest = parseInt(page_data.menual_total.value_profit - page_data.menual_total.interest_amount);
      page_data.menual_total.duty_amount = parseInt(page_data.menual_total.value_interest * (page_data.menual_total.duty ? page_data.menual_total.duty : 0) / 100);
      page_data.menual_total.duty_amount = parseInt(page_data.menual_total.value_interest * (page_data.menual_total.duty ? page_data.menual_total.duty : 0) / 100);
      page_data.menual_total.final_value = parseInt(page_data.menual_total.value_interest - page_data.menual_total.duty_amount - (page_data.menual_total.extra_expense ? page_data.menual_total.extra_expense : 0));
    }

    const get_params = () => {
      axios({
        url: 'api/master/get_all_parameters',
        method: 'post',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { Accept: 'application/json' } }
      }).then(res => {
        diamond.colors = res.data.color
        diamond.cuts = res.data.cut
        diamond.purities = res.data.purity
        diamond.polish = res.data.polish
        diamond.shapes = res.data.shape
        diamond.symmetry = res.data.symmetry
        diamond.fluerocents = res.data.fluerocent
        diamond.tensions = res.data.tension
        diamond.stones = res.data.stone
        diamond.heights = res.data.height
        diamond.tabls = res.data.tabl
        diamond.ratios = res.data.ratio
        diamond.browns = res.data.browns
        diamond.diamond_types = res.data.diamond_types
        diamond.inclusion_types = res.data.inclusion_types
        // page_data.labs = res.data.lab
      })
    }

    const new_planning = (item) => {

      page_data.manualPlanning = [];
      getPlanningTotal();
      add_plan_fields();

      if(page_data.tenderDetail.active_status == 1) {
        page_data.planning_packet = item;
        cash('#new-manual-planning').modal('show');

      } else if(page_data.tenderDetail.active_status == 2) {
        swal("Warning", "Teder Already Won", "warning");

      } else if(page_data.tenderDetail.active_status == 3) {
        swal("Warning", "Teder Already Cancelled", "warning");
      }
    }
    const update_planning = (update_plan) => {


      if(page_data.tenderDetail.active_status == 1) {
        // page_data.planning_packet = item;
        page_data.manualPlanDetail = update_plan;
        page_data.manualPlanning = update_plan.plan_detail;
        // page_data.menual_total.weight = page_data.manualPlanDetail.weight;
        page_data.menual_total.polish_weight = Number(page_data.manualPlanDetail.polish_weight);
        page_data.menual_total.value = Number(page_data.manualPlanDetail.value);
        page_data.menual_total.duty = Number(page_data.manualPlanDetail.duty);
        page_data.menual_total.duty_amount = Number(page_data.manualPlanDetail.duty_amount);
        page_data.menual_total.labour_per_ct = Number(page_data.manualPlanDetail.labour_per_ct);
        page_data.menual_total.total_labour = Number(page_data.manualPlanDetail.total_labour);
        page_data.menual_total.profit = Number(page_data.manualPlanDetail.profit);
        page_data.menual_total.profit_amount = Number(page_data.manualPlanDetail.profit_amount);
        page_data.menual_total.value_profit = Number(page_data.manualPlanDetail.value_profit);
        page_data.menual_total.value_interest = Number(page_data.manualPlanDetail.value_interest);
        page_data.menual_total.interest = Number(page_data.manualPlanDetail.interest);
        page_data.menual_total.interest_duration = Number(page_data.manualPlanDetail.interest_duration);
        page_data.menual_total.interest_amount = Number(page_data.manualPlanDetail.interest_amount);
        page_data.menual_total.final_value = Number(page_data.manualPlanDetail.final_value);

        cash('#new-manual-planning').modal('show');

      } else if(page_data.tenderDetail.active_status == 2) {
        swal("Warning", "Teder Already Won", "warning");

      } else if(page_data.tenderDetail.active_status == 3) {
        swal("Warning", "Teder Already Cancelled", "warning");
      }
    }

    function submitManualPlanning() {

      let submit = true
      page_data.manualPlanning.forEach(plan => {
        if(plan.weight == '' || plan.polish_weight == '' || plan.color == '' || plan.cut == '' || plan.purity == '' || plan.polish == '' || plan.shape == '' || plan.symmetry == '' || plan.fluerocent == '' || plan.height == '' || plan.ratio == '' || plan.tabl == '') {
          swal("Warning!", "Fileds are Empty !", "warning")
          submit = false
        }
      });

      if(submit == true) {

        let request_url = "";
        let id = "";

        if(page_data.manualPlanDetail.id) {

          id = page_data.manualPlanDetail.id;

          request_url = 'api/tender/update_plan';
        } else {

          request_url = 'api/tender/create_plan';
        }

        page_data.loading = true
        let params = {
          packet_plans :page_data.manualPlanning,
          menual_total : page_data.menual_total,
          id : id,
          packet_id :page_data.planning_packet.id
        }

        axios({
          url: request_url,
          method: 'post',
            baseURL: BASE_URL,
            data: params,
            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
            config: { headers: { 'Accept':'application/json' } }
          }).then((res) => {

            page_data.loading = false
            if(res.data.status == "error") {

              swal("Warning", res.data.msg, "warning");
            } else {

              cash('#new-manual-planning').modal('hide')
              page_data.manualPlanDetail = []
              page_data.planning_packet = []
              page_data.manualPlanning = []
              page_data.menual_total.weight = 0
              page_data.menual_total.polish_weight = 0
              page_data.menual_total.value = 0
              page_data.menual_total.duty = 0
              page_data.menual_total.duty_amount = 0
              page_data.menual_total.labour_per_ct = 0
              page_data.menual_total.total_labour = 0
              page_data.menual_total.profit = 0
              page_data.menual_total.profit_amount = 0
              page_data.menual_total.value_profit = 0
              page_data.menual_total.value_interest = 0
              page_data.menual_total.interest = ""
              page_data.menual_total.interest_duration = ""
              page_data.menual_total.interest_amount = 0
              page_data.menual_total.final_value = 0
              swal("Success", res.data.msg, "success");

              getTenderDetails(route.params.id)
            }

          getPacketPlans(page_data.pack_id)
          page_data.total_items = 1
          page_data.pack_id = ''
          page_data.pack_weight = ''
          page_data.plan_id = ''
          page_data.message = res.data.success

          page_data.menual_total.polish_weight = 0
          page_data.menual_total.weight = 0
          page_data.menual_total.value = 0
          initTabulator()
          setTimeout(() => {
            basicNonStickyNotificationPacketSuccess()
          },100)
          setTimeout(() => {
            page_data.message = ''
          }, 3000);
          page_data.loading = false
          }).catch((err) => {
            page_data.error = err.response.data.error
            page_data.loading = false
            setTimeout(() => {
            basicNonStickyNotificationPacket()
          },100)
          setTimeout(() => {
            page_data.error = ''
          }, 3000);
          })
      }

    }

    const cancel_plan = id => {
      const params = { id: btoa(id) }

      swal({
        title: 'Are you sure?',
        text: 'Do you want to delete plan?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, cancel it!',
        cancelButtonText: 'No, keep it'
      }).then(result => {
        if (result.value) {
          axios({
            url: 'api/tender/cancle_plan',
            method: 'post',
            baseURL: BASE_URL,
            data: params,
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token')
            },
            config: { headers: { Accept: 'application/json' } }
          })
            .then(res => {

              if(res.data.status == "error") {
                swal("Warning", res.data.mfg, "warning")
              } else {
                page_data.menual_total.labour_per_ct = 0
                page_data.menual_total.profit = 0
                page_data.menual_total.interest = ""
                page_data.menual_total.interest_duration = ""

                getTenderDetails(route.params.id)
                cash('#view-manual-planning').modal('hide');

                swal({
                  text: res.data.msg,
                  icon: 'success',
                  buttonsStyling: false,
                  confirmButtonText: 'Ok, got it!',
                  heightAuto: false,
                  customClass: {
                    confirmButton: 'btn btn-sm btn-primary btm-sm py-2'
                  }
                })
              }
              initTabulator()
            })
            .catch(error => {
              // Handle error if needed
            })
        }
      })
    }

    const view_planning = (item) => {
      page_data.planning_view = item;
    }


    onMounted(() => {
      getTenderDetails(route.params.id)
      get_params()
    })
    return {
      page_data,
      tabulator,
      getTenderDetails,
      baseURL,
      add_plan_fields,
      removeField,
      diamond,
      new_planning,
      loadPlanningValues,
      calculate_value,
      getPlanningTotal,
      update_planning,
      view_planning,
      submitManualPlanning,
      cancel_plan,
      updateSize
      // onMounted
      // TotalAmount
    }
  }
})
</script>
<style scoped>
.text-green-800 {
  color: #03543f;
}
.bg-green-100 {
  background-color: #def7ec;
}
.bg-red-100 {
  background-color: #fde8e8;
}
.text-red-800 {
  color: #9b1c1c;
}
.text-yellow-800 {
  color: #723b13;
}
.bg-yellow-100 {
  background-color: #fdf6b2;
}
.text-blue-800 {
  color: #1e429f;
}
.bg-blue-100 {
  background-color: #e1effe;
}
a:link {
  text-decoration: underline;
}
</style>
